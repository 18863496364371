export default {
  // mutationBasicData (state, payload) {
  //   state.basicData = payload
  // },
  // mutationRiskData (state, payload) {
  //   state.riskData = payload
  // },
  // mutationOperatingData (state, payload) {
  //   state.operatingData = payload
  // },
  // mutationFinanceData (state, payload) {
  //   state.financeData = payload
  // },
  mutationCompanyNews (state, payload) {
    state.companyNews = payload
  },
  mutationCompanyData (state, payload) {
    state.companyData = payload
  },
  mutationDownloadCompanyData (state, payload) {
    state.downloadCompanyData = payload
  },
  mutationIsGoFullLoading (state, payload) {
    state.isGoFullLoading = payload
  },
  mutationGoUniID (state, payload) {
    state.goUniID = payload
  },
  mutationCompanyGoFull (state, payload) {
    state.companyGoFull = payload
  },
  mutationCompanyGoOld (state, payload) {
    state.companyGoOld = payload
  },
}
