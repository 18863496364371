export default {
  mutationUniIDList (state, payload) {
    state.uniIDList = payload
  },
  mutationGetList (state, payload) {
    state.getList = payload
  },
  mutationShowList (state, payload) {
    state.showList = payload
    // -- 顯示區塊 --
    let isShowDescription = false
    let isShowBasic = false
    let isShowShareholders = false
    let isShowManagers = false
    let isShowCompanysHistory = false
    let isShowCompanyBranchs = false
    let isShowFactorys = false
    let isShowSimilarAddress = false
    let isShowVerdicts = false
    let isShowNews = false
    let isShowStockRelationshipChart = false
    let isShowStockDiractedChart = false
    let isShowDisputes = false
    let isShowProcurementBadVendors = false
    let isShowTrades = false
    let isShowProcurements = false
    let isShowJobs = false
    let isShowPatents = false
    let isShowAwards = false
    let isShowPublicsNews = false
    let isShowRevenueHistory = false
    let isShowIfrssSummary = false
    payload.forEach(d => {
      if (d === 'description') {
        isShowDescription = true
      } else if (d === 'basic') {
        isShowBasic = true
      } else if (d === 'shareholders') {
        isShowShareholders = true
      } else if (d === 'managers') {
        isShowManagers = true
      } else if (d === 'companys_history') {
        isShowCompanysHistory = true
      } else if (d === 'company_branchs') {
        isShowCompanyBranchs = true
      } else if (d === 'factorys') {
        isShowFactorys = true
      } else if (d === '登記地址近似公司') {
        isShowSimilarAddress = true
      } else if (d === 'verdicts') {
        isShowVerdicts = true
      } else if (d === 'news') {
        isShowNews = true
      } else if (d === 'stock_relationship_chart') {
        isShowStockRelationshipChart = true
      } else if (d === 'stock_directed_chart') {
        isShowStockDiractedChart = true
      } else if (d === 'disputes') {
        isShowDisputes = true
      } else if (d === 'procurement_bad_vendors') {
        isShowProcurementBadVendors = true 
      } else if (d === 'trades') {
        isShowTrades = true
      } else if (d === 'procurements') {
        isShowProcurements = true
      } else if (d === 'jobs') {
        isShowJobs = true
      } else if (d === 'patents') {
        isShowPatents = true
      } else if (d === 'awards') {
        isShowAwards = true
      } else if (d === 'publics_news') {
        isShowPublicsNews = true
      } else if (d === 'revenue_history') {
        isShowRevenueHistory = true
      } else if (d === 'ifrss_summary') {
        isShowIfrssSummary = true
      }
    })
    state.isShowDescription = isShowDescription
    state.isShowBasic = isShowBasic
    state.isShowShareholders = isShowShareholders
    state.isShowManagers = isShowManagers
    state.isShowCompanysHistory = isShowCompanysHistory
    state.isShowCompanyBranchs = isShowCompanyBranchs
    state.isShowFactorys = isShowFactorys
    state.isShowSimilarAddress = isShowSimilarAddress
    state.isShowVerdicts = isShowVerdicts
    state.isShowNews = isShowNews
    state.isShowStockRelationshipChart = isShowStockRelationshipChart
    state.isShowStockDiractedChart = isShowStockDiractedChart
    state.isShowDisputes = isShowDisputes
    state.isShowProcurementBadVendors = isShowProcurementBadVendors
    state.isShowTrades = isShowTrades
    state.isShowProcurements = isShowProcurements
    state.isShowJobs = isShowJobs
    state.isShowPatents = isShowPatents
    state.isShowAwards = isShowAwards
    state.isShowPublicsNews = isShowPublicsNews
    state.isShowRevenueHistory = isShowRevenueHistory
    state.isShowIfrssSummary = isShowIfrssSummary
  },
  mutationIsCompanyDataLoading (state, payload) {
    state.isCompanyDataLoading = payload
  },
  mutationIsCompanyNewsLoading (state, payload) {
    state.isCompanyNewsLoading = payload
  },
  mutationCompanyList (state, payload) {
    state.companyList = payload
  },
  mutationMultiCompanyNews (state, payload) {
    state.multiCompanyNews = payload
  },
  mutationMultiCompanyData (state, payload) {
    state.multiCompanyData = payload
  },
  mutationMultiGoFull (state, { uniID, payload }) {
    state.multiGoFull = {
      ...state.multiGoFull,
      [uniID]: payload
    }
  },
  mutationMultiGoOld (state, { uniID, payload }) {
    state.multiGoOld = {
      ...state.multiGoOld,
      [uniID]: payload
    }
  },
  mutationResetMultiGo (state, payload) {
    state.multiGoFull = {}
    state.multiGoOld = {}
  },
  // mutationIsGoFullLoading (state, payload) {
  //   state.multiIsGoFullLoading = payload
  // },
  // mutationIsGoOldLoading (state, payload) {
  //   state.multiIsGoOldLoading = payload
  // },
}
