import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters.js'
import state from './state.js'
import mutations from './mutations.js'
import actions from './actions.js'
import companyDetail from './modules/companyDetail/index.js'
import companyList from './modules/companyList/index.js'
import multiCompanyDetail from './modules/multiCompanyDetail/index.js'
import multiPersonDetail from './modules/multiPersonDetail/index.js'
import home from './modules/home/index.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    companyDetail,
    companyList,
    multiCompanyDetail,
    multiPersonDetail,
    home
  },
  getters,
  state,
  mutations,
  actions,
  // 嚴格模式，禁止直接修改 state
  strict: true
})
