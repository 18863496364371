export default function to_old_format(ret_data){
	// 建立 id->index 的快查表
	let book = {};
	for(let i=0;i<ret_data['d3']['nodes'].length;i++){
		let now_node = ret_data['d3']['nodes'][i];
		book[now_node['uniID']] = i;
	}


	// 先 build edges
	let edges = [];
	for(let i=0;i<ret_data['d3']['nodes'].length;i++){
		// 有上游的情況代表有一條 edge 待建立
		let now_node = ret_data['d3']['nodes'][i];
		let now_node_uniid = now_node['uniID'];
		for(let j=0;j<now_node['ups'].length;j++){
			let up = now_node['ups'][j];
			let up_uniid = up['uniID'];
			let up_idx = book[up_uniid];
			let new_edge = {
				'edge-role': up['edge_types'].join("、"),
				'id': up_uniid + "->" + now_node_uniid,
				'parent-role': ret_data['d3']['nodes'][up_idx]['role'],
				'percentage': up['percentage'],
				'source': up_idx,
				'source-uniID': up_uniid,
				'target': i,
				'target-uniID': now_node_uniid
			}
			edges.push(new_edge);
		}
	}
	ret_data['d3']['edges'] = edges;

	let edge_id_2_edge = {};
	for(let i=0;i<edges.length;i++){
		let eid = edges[i]['id'];
		edge_id_2_edge[eid] = edges[i];
	}

	// 然後 build nodes(主要是裡面的routes*)
	for(let i=0;i<ret_data['d3']['nodes'].length;i++){
		if(ret_data['d3']['nodes'][i]['name']==null)
			ret_data['d3']['nodes'][i]['name'] = ret_data['d3']['nodes'][i]['company_name'];
		
		if(ret_data['d3']['nodes'][i]['managers']==null)
			ret_data['d3']['nodes'][i]['managers']=[];
		if(ret_data['d3']['nodes'][i]['shareholders']==null)
			ret_data['d3']['nodes'][i]['shareholders']=[];
		if(ret_data['d3']['nodes'][i]['representative_name']==null)
			ret_data['d3']['nodes'][i]['representative_name']="";
		if(ret_data['d3']['nodes'][i]['company_operate']==null)
			ret_data['d3']['nodes'][i]['company_operate']="";
		if(ret_data['d3']['nodes'][i]['company_state']==null)
			ret_data['d3']['nodes'][i]['company_state']="";

		ret_data['d3']['nodes'][i]['detail'] = {
			'payload':{
				'basic':{
					'company_name':ret_data['d3']['nodes'][i]['name'],
					'managers':ret_data['d3']['nodes'][i]['managers'],
					'shareholders':ret_data['d3']['nodes'][i]['shareholders'],
					'representative_name':ret_data['d3']['nodes'][i]['representative_name'],
					'uniID':ret_data['d3']['nodes'][i]['uniID'],
					'company_operate':ret_data['d3']['nodes'][i]['company_operate'],
					'company_state':ret_data['d3']['nodes'][i]['company_state'],
				}
			}
		}
	}
	for(let i=0;i<ret_data['d3']['nodes'].length;i++){
		if(ret_data['d3']['nodes'][i]['managers']!=null)
			delete ret_data['d3']['nodes'][i]['managers'];
		if(ret_data['d3']['nodes'][i]['shareholders']!=null)
			delete ret_data['d3']['nodes'][i]['shareholders'];
		if(ret_data['d3']['nodes'][i]['representative_name']!=null)
			delete ret_data['d3']['nodes'][i]['representative_name'];
		if(ret_data['d3']['nodes'][i]['company_operate']!=null)
			delete ret_data['d3']['nodes'][i]['company_operate'];
		if(ret_data['d3']['nodes'][i]['company_state']!=null)
			delete ret_data['d3']['nodes'][i]['company_state'];
		ret_data['d3']['nodes'][i]['stock-up'] = [];
		for(let j=0;j<ret_data['d3']['nodes'][i]['ups'].length;j++){
			let up_uid = ret_data['d3']['nodes'][i]['ups'][j]['uniID'];
			ret_data['d3']['nodes'][i]['stock-up'].push(up_uid);
		}
		delete ret_data['d3']['nodes'][i]['ups'];
		ret_data['d3']['nodes'][i]['stock-down'] = [];
		for(let j=0;j<ret_data['d3']['nodes'][i]['downs'].length;j++){
			let down_uid = ret_data['d3']['nodes'][i]['downs'][j];
			ret_data['d3']['nodes'][i]['stock-down'].push(down_uid);
		}
		delete ret_data['d3']['nodes'][i]['downs'];
	}
	// 最後的 routes
	for(let i=0;i<ret_data['d3']['nodes'].length;i++){
		// 如果有 routes
		if(ret_data['d3']['nodes'][i]['routes']!=null){
			// 製作 routes-in-id 與 routes-in-name
			ret_data['d3']['nodes'][i]['routes-in-id']=[];
			ret_data['d3']['nodes'][i]['routes-in-name']=[];
			ret_data['d3']['nodes'][i]['routes-of-stock']="到 ROOT 共有 " + ret_data['d3']['nodes'][i]['routes'].length + " 條路徑";
			ret_data['d3']['nodes'][i]['routes-of-stock-detail']=[];
			ret_data['d3']['nodes'][i]['routes-of-stock-detail-single-value']=[];
			for(let r_idx=0;r_idx<ret_data['d3']['nodes'][i]['routes'].length;r_idx++){
				let route_now = ret_data['d3']['nodes'][i]['routes'][r_idx];
				// 開頭的點的ID與名字
				let pre_name = ret_data['d3']['nodes'][i]['name'];
				let pre_id = ret_data['d3']['nodes'][i]['uniID'];
				// 路徑
				let route_in_id = [];
				let route_in_name = [];
				for(let node_idx=0;node_idx<route_now.length;node_idx++){
					let new_edge_id = pre_id + '->' + route_now[node_idx];
					let new_edge_name = pre_name + '->' + ret_data['d3']['nodes'][book[route_now[node_idx]]]['name'];
					route_in_id.push(new_edge_id);
					route_in_name.push(new_edge_name);
					pre_id = route_now[node_idx];
					pre_name = ret_data['d3']['nodes'][book[route_now[node_idx]]]['name'];
				}
				ret_data['d3']['nodes'][i]['routes-in-id'].push(route_in_id);
				ret_data['d3']['nodes'][i]['routes-in-name'].push(route_in_name);
			}
			for(let r_idx=0;r_idx<ret_data['d3']['nodes'][i]['routes-in-id'].length;r_idx++){
				let route_now = ret_data['d3']['nodes'][i]['routes-in-id'][r_idx];
				let routes_of_stock_detail_one = [];
				let sv = 100;
				for(let e_idx=0;e_idx<route_now.length;e_idx++){
					let edge_id = route_now[e_idx];
					let perc = edge_id_2_edge[edge_id]['percentage'];
					routes_of_stock_detail_one.push(perc);
					sv = sv * perc / 100;
				}
				ret_data['d3']['nodes'][i]['routes-of-stock-detail'].push(routes_of_stock_detail_one);
				ret_data['d3']['nodes'][i]['routes-of-stock-detail-single-value'].push(sv);
				
			}
			// total-investment-ratio
			let tir = 0;
			for(let tmp_idx=0;tmp_idx<ret_data['d3']['nodes'][i]['routes-of-stock-detail-single-value'].length;tmp_idx++){
				tir += ret_data['d3']['nodes'][i]['routes-of-stock-detail-single-value'][tmp_idx];
			}
			ret_data['d3']['nodes'][i]['total-investment-ratio'] = tir;
		}
	}
	// 把實質受益人放上去
	let target_idx = -1;
	for(let i=0;i<ret_data['d3']['nodes'].length;i++){
		if(ret_data['d3']['nodes'][i]['Substantial-beneficiary'] != null){
			target_idx = i;
			break;
		}
	}
	if(target_idx!=-1){
		for(let i=0;i<ret_data['d3']['nodes'].length;i++){
			let node_2_check = ret_data['d3']['nodes'][i];
			// 如果他有 routes
			if(node_2_check['routes']!=null){
				let node_name = node_2_check['name'];
				let node_uniid = node_2_check['uniID'];
				let node_total = node_2_check['total-investment-ratio'];
				let node_role = node_2_check['role'];
				// 計算direct數值(只有一個線段的route)
				let direct_value = 0;
				for(let r_idx=0;r_idx<node_2_check['routes'].length;r_idx++){
					if(node_2_check['routes-of-stock-detail'][r_idx].length==1)
						direct_value += node_2_check['routes-of-stock-detail-single-value'][r_idx];
				}
				let indirect_value = node_total - direct_value;
				ret_data['d3']['nodes'][target_idx]['Substantial-beneficiary'].push(
					{
						'direct': direct_value,
						'indirect': indirect_value,
						'name': node_name,
						'role': node_role,
						'total': node_total,
						'uniID': node_uniid
					}
				);
			}
		}
		ret_data['d3']['nodes'][target_idx]['Substantial-beneficiary'].sort((a,b) => (a.total > b.total) ? -1 : ((b.total > a.total) ? 1 : 0))
	}
	return ret_data;
}