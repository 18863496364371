// vue
import Vue from "vue";
import App from "./App.vue";
import router from "@/router/index.js";
import store from "@/store/index.js";
import VueCompositionAPI from '@vue/composition-api'

// libraries
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/zh-TW'
import Cookies from 'js-cookie'

// style
import '@/assets/scss/style.scss'
import '@/assets/scss/element-variables.scss'

// fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// global filters
import '@/filters/numberWithComma.js'
import '@/filters/emptyFormat.js'

// global component
// import HighLightContent from '@/components/HighLightContent/HighLightContent.vue'
// import '@/components/HighLightContent/HighLightContent.js'

// import * as VueGoogleMaps from 'vue2-google-maps'
// Vue.use(VueGoogleMaps, {
//   load: { key: 'AIzaSyAyXHOubGC0vxqg0NdaSAwXUTBkPWqIl6Q' }
// })

// 假資料
// import '@/mock/index.js'

// @ts-ignore
library.add(fas, far)
// dom.watch()
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(ElementUI, { locale })
Vue.use(VueCompositionAPI)

Vue.config.productionTip = false;

// -- global error handling --
Vue.config.errorHandler = (err, vm, info) => {
  // err: error trace
  // vm: component in which error occured
  // info: Vue specific error information such as lifecycle hooks, events etc.
  // console.log('--errorHandler--')
  // console.log('err:')
  // console.log(err)
  // console.log('vm:')
  // console.log(vm)
  // console.log('info:')
  // console.log(info)
  // TODO: Perform any custom logic or log to server

};

// -- init --
// 取得瀏覽器儲存的token
try {
  let isLoginKept = Cookies.get('bp__dbs__isLoginKept')
  if (isLoginKept == 'true' || isLoginKept == true) {
    store.commit('mutationIsLoginKept', true)
  }
  let jwtToken = Cookies.get('bp__dbs__token') || sessionStorage.getItem('bp__dbs__token')
  if (jwtToken) {
    store.commit('mutationJwtToken', jwtToken)
  }
  let userInfo = Cookies.get('bp__dbs__userInfo') || sessionStorage.getItem('bp__dbs__userInfo')
  if (userInfo) {
    store.commit('mutationUserInfo', JSON.parse(userInfo))
  }
} catch (e) {}

new Vue({
  // @ts-ignore
  router,
  store,
  render: h => h(App)
}).$mount("#app");
