export default {
  // 公司類型
  getterMultiStockType (state, getters, rootState, rootGetters) {
    return state.multiCompanyData.map(d => {
      if (d.basic && d.basic.stock_type ) {
        return d.basic.stock_type
      }
      return ''
    })
  },
}
