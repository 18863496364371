import * as apis from '@/apis/index.ts'
import toOldFormatV4 from '@/utils/to_old_format_v4.js'
import toOldFormat from '@/utils/to_old_format.js'
// import moment from 'moment'

export default {
  // 公司資料（取得除了news以外的所有資料）
  async actionCompanyDetail ({ dispatch, commit, state, rootGetters }, uniID) {
    commit('mutationCompanyData', {})
    commit('mutationCompanyGoFull', {
      nodes: [],
      edges: []
    })
    commit('mutationCompanyGoOld', {
      nodes: [],
      edges: []
    })
    // 取得所有資料（news除外）
    let searchRequest = {
      get: {},
      uniID
    }
    // -- 依權限取得資料 --
    if (rootGetters.getterLimitD === false &&
    rootGetters.getterLimitE === false &&
    rootGetters.getterLimitF === false &&
    rootGetters.getterLimitG === false) {
      return false
    }
    // 基本資料
    if (rootGetters.getterLimitD) {
      searchRequest.get.shareholders = {}
      searchRequest.get.managers = {}
      searchRequest.get.companys_history = {}
      searchRequest.get.company_branchs = {}
      searchRequest.get.factorys = {}
      searchRequest.get.登記地址近似公司 = {}
    }
    
    if (true === true) {
      // searchRequest.get.stock = {} // @Q@ 關係人地圖 權限api還沒有
      searchRequest.get.verdicts = {} // @Q@ 法律訴訟 權限api還沒有
    }
    // 風險指標
    if (rootGetters.getterLimitE) {
      // searchRequest.get.news = {}
      searchRequest.get.disputes = {}
      searchRequest.get.procurement_bad_vendors = {}
      searchRequest.get.data_gov_decision = {}
      searchRequest.get.data_gov_environmental_punishment = {}
      searchRequest.get.epa = {}
    }
    // 經營指標
    if (rootGetters.getterLimitF) {
      searchRequest.get.trades = {}
      searchRequest.get.procurements_award = {}
      searchRequest.get.job104s_jobs = {}
      searchRequest.get.patent_rights = {}
      searchRequest.get.awards = {}
    }
    // 財務指標
    if (rootGetters.getterLimitG) {
      searchRequest.get.publics_news = {}
      searchRequest.get.revenue_history = {}
      searchRequest.get.ifrss_summary = {}
    }
    let result = await apis.companyGet(searchRequest)
      .then((data) => {
        // 全部的資料
        commit('mutationCompanyData', data.payload)
        return true
      })
      .catch(e => {
        return false
      })
    return result
  },
  // 單獨取新聞資料
  async actionCompanyNews ({ dispatch, commit, state, rootState }, uniID) {
    commit('mutationCompanyNews', null)
    const searchRequest = {
      get: {
        news: {
          // 'remove-duplicate': 1
          // 陣列轉物件
          'cls-custom': rootState.riskKeywordGroup.reduce((prev, current) => {
            if (current.switch == 0) {
              return prev
            }
            return {
              ...prev,
              [current.keyword_group_name]: current.keywords
            }
          }, {})
        }
      },
      uniID
    }
    let result = await apis.companyGet(searchRequest)
      .then((data) => {
        commit('mutationCompanyNews', data.payload.news)
        return true
      })
      .catch(e => {
        return false
      })
    return result
  },
  // // 下載公司資料
  // async actionDownloadCompanyDetail ({ dispatch, commit, state, rootGetters }, uniID) {
  //   commit('mutationDownloadCompanyData', null)

  //   const fiveYearsBefore = moment().subtract(5, 'years').format('YYYY-MM-DD')
  //   // 取得所有資料（news除外）
  //   let searchRequest = {
  //     get: {},
  //     uniID
  //   }
  //   // -- 依權限取得資料 --
  //   if (rootGetters.getterLimitD === false &&
  //   rootGetters.getterLimitE === false &&
  //   rootGetters.getterLimitF === false &&
  //   rootGetters.getterLimitG === false) {
  //     return false
  //   }
  //   // 基本資料
  //   if (rootGetters.getterLimitD) {
  //     searchRequest.get.shareholders = {
  //       "page-size": 10000
  //     }
  //     searchRequest.get.managers = {
  //       "page-size": 10000
  //     }
  //     searchRequest.get.companys_history = {
  //       "page-size": 10000,
  //       "time": {
  //         "start": fiveYearsBefore
  //       }
  //     }
  //     searchRequest.get.company_branchs = {
  //       "page-size": 10000
  //     }
  //     searchRequest.get.factorys = {
  //       "page-size": 10000
  //     }
  //   }
  //   // 風險指標
  //   if (rootGetters.getterLimitE) {
  //     searchRequest.get.verdicts = {
  //       "page-size": 100,
  //       "time": {
  //         "start": fiveYearsBefore
  //       }
  //     }
  //     searchRequest.get.news = {
  //       "page-size": 100,
  //       "time": {
  //         "start": fiveYearsBefore
  //       }
  //     }
  //     searchRequest.get.disputes = {
  //       "page-size": 100,
  //       "time": {
  //         "start": fiveYearsBefore
  //       }
  //     }
  //   }
  //   // 經營指標
  //   if (rootGetters.getterLimitF) {
  //     searchRequest.get.trades = {
  //       "time": {
  //         "start": fiveYearsBefore
  //       }
  //     }
  //     searchRequest.get.procurements = {
  //       "page-size": 100
  //     }
  //     searchRequest.get.patents = {
  //       "page-size": 100
  //     }
  //     searchRequest.get.awards = {
  //       "page-size": 100,
  //       "time": {
  //         "start": fiveYearsBefore
  //       }
  //     }
  //   }
  //   // 財務指標
  //   if (rootGetters.getterLimitG) {
  //     searchRequest.get.publics_news = {
  //       "page-size": 10000
  //     }
  //     searchRequest.get.revenue_history = {
  //       "page-size": 10000
  //     }
  //     searchRequest.get.ifrss_summary = {
  //       "page-size": 10000
  //     }
  //   }
  //   let result = await apis.companyGet(searchRequest)
  //     .then((data) => {
  //       // 全部的資料
  //       commit('mutationDownloadCompanyData', data.payload)
  //       return true
  //     })
  //     .catch(e => {
  //       return false
  //     })
  //   return result
  // },
  // 商業網絡圖資料
  async actionCompanyGraphForDbs ({ dispatch, commit, state }, uniID) {
    if (state.goUniID == uniID) {
      return // 查過同樣的就不查了
    }
    
    commit('mutationIsGoFullLoading', true)
    commit('mutationCompanyGoFull', {
      nodes: [],
      edges: []
    })
    commit('mutationCompanyGoOld', {
      nodes: [],
      edges: []
    })
    const fullPromise = apis.graphForDbs({
      uniid: uniID,
      skip_gov_neibor: '1',
      skip_independent_director_neibor: '1',
      only_in_bussiness: false
    })
    .then(data => {
      const parsedData = toOldFormatV4(data)
      console.log('toOldFormatV4 - full', parsedData)
      commit('mutationCompanyGoFull', {
        nodes: parsedData.d3.nodes,
        edges: parsedData.d3.edges,
        rootId: uniID
      })
      return true
    })
    .catch(d => {
      return Promise.reject(d)
    })
    const upPromise = apis.graphForDbsOld({
      uniid: uniID,
      "mode": "up",
      "format": "json",
      "mini": "0",
      "scope": "classic",
      "force_split": "1",
      only_in_bussiness: false
    })
    .then(data => {
      const parsedData = toOldFormat(data)
      console.log('toOldFormat - up', parsedData)
      commit('mutationCompanyGoOld', {
        nodes: parsedData.d3.nodes,
        edges: parsedData.d3.edges,
        rootId: uniID
      })
      return true
    })
    .catch(d => {
      return Promise.reject(d)
    })

    const result = await Promise.all([fullPromise, upPromise])
      .then((d) => {
        commit('mutationIsGoFullLoading', false)
        commit('mutationGoUniID', uniID)
        return true
      })
      .catch(d => {
        console.error(d)
        return false
      })

    return result
  },
}
