<template>
  <div id="app" class="wrapper">
    <router-view></router-view>
    <div
      style="width:100%;height:100%;position:fixed;top:0;left:0;z-index:2000"
      v-if="isFullPageLoading">
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'app',
  data () {
    return {
    }
  },
  computed: {
    ...mapState([
      'isFullPageLoading',
      'originRouteQuery'
    ])
  },
  methods: {
  },
  created () {
    const html = document.documentElement
    html.setAttribute('lang', 'zh-Hant-TW')
  }
}
</script>
