export default {
  dataTypeOptions: [], // 公司類型
  companyStateOptions: [], // 1.公司狀態
  etaxMattersOptions: [], // 行業別
  companyOperateOptions: [], // 營業狀態
  companyCountyOptions: [], // 2.所在地區
  companyCountySubOptions: {},
  dateApprovedOptions: [], // 3.成立年限
  capitalAmountOptions: [], // 4.資本總額
  employeeCountOptions: [], // 5.員工人數
  stockStateOptions: [], // 6.股權狀況
  importsOptions: [], // 8.最近一年度總進口實績
  exportsOptions: [], // 9.最近一年度總出口實績
  procurementHistoryOptions: [], // 10.最近一個月得標金額
  // companyOperateOptions: [],
  
}
