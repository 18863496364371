export default {
  getterUserEmail (state, getters, rootState, rootGetters) {
    return state.userInfo.email || ''
  },
  // 是否有管理者權限
  getterIsManager (state, getters, rootState, rootGetters) {
    if (state.userLimits) {
      // 擁有其中一個的權限
      if (state.userLimits['A'] === 'true' ||
      state.userLimits['B-1'] === 'true' ||
      state.userLimits['B-2'] === 'true' ||
      state.userLimits['B-3'] === 'true' ||
      state.userLimits['B-4'] === 'true' ||
      state.userLimits['B-5'] === 'true' ||
      state.userLimits['B-6'] === 'true' ||
      state.userLimits['B-7'] === 'true' ||
      state.userLimits['B-8'] === 'true' ||
      state.userLimits['B-9'] === 'true' ||
      state.userLimits['C-1'] === 'true' ||
      state.userLimits['C-2'] === 'true') {
        return true
      }
    }
    return false
  },
  // 權限A
  getterLimitA (state, getters, rootState, rootGetters) {
    return state.userLimits['A'] === 'true'
  },
  // 權限B-1
  getterLimitB1 (state, getters, rootState, rootGetters) {
    return state.userLimits['B-1'] === 'true'
  },
  // 權限B-2
  getterLimitB2 (state, getters, rootState, rootGetters) {
    return state.userLimits['B-2'] === 'true'
  },
  // 權限B-3
  getterLimitB3 (state, getters, rootState, rootGetters) {
    return state.userLimits['B-3'] === 'true'
  },
  // 權限B-4
  getterLimitB4 (state, getters, rootState, rootGetters) {
    return state.userLimits['B-4'] === 'true'
  },
  // 權限B-5
  getterLimitB5 (state, getters, rootState, rootGetters) {
    return state.userLimits['B-5'] === 'true'
  },
  // 權限B-6
  getterLimitB6 (state, getters, rootState, rootGetters) {
    return state.userLimits['B-6'] === 'true'
  },
  // 權限B-7
  getterLimitB7 (state, getters, rootState, rootGetters) {
    return state.userLimits['B-7'] === 'true'
  },
  // 權限B-8
  getterLimitB8 (state, getters, rootState, rootGetters) {
    return state.userLimits['B-8'] === 'true'
  },
  // 權限B-9
  getterLimitB9 (state, getters, rootState, rootGetters) {
    return state.userLimits['B-9'] === 'true'
  },
  // 權限C-1
  getterLimitC1 (state, getters, rootState, rootGetters) {
    return state.userLimits['C-1'] === 'true'
  },
  // 權限C-2
  getterLimitC2 (state, getters, rootState, rootGetters) {
    return state.userLimits['C-2'] === 'true'
  },
  // 權限D
  getterLimitD (state, getters, rootState, rootGetters) {
    return state.userLimits['D'] === 'true'
  },
  // 權限E
  getterLimitE (state, getters, rootState, rootGetters) {
    return state.userLimits['E'] === 'true'
  },
  // 權限F
  getterLimitF (state, getters, rootState, rootGetters) {
    return state.userLimits['F'] === 'true'
  },
  // 權限G
  getterLimitG (state, getters, rootState, rootGetters) {
    return state.userLimits['G'] === 'true'
  },
  // 權限H
  getterLimitH (state, getters, rootState, rootGetters) {
    return state.userLimits['H'] === 'true'
  },
  // 申請註冊的使用者
  getterAppliedUsers (state, getters, rootState, rootGetters) {
    if (state.users && state.users.length) {
      return state.users.filter(d => d.status === '註冊')
    } else {
      return []
    }
  },
  // 已註冊的使用者
  getterRegisteredUsers (state, getters, rootState, rootGetters) {
    if (state.users && state.users.length) {
      return state.users.filter(d => d.status === '啟用' || d.status === '停權')
    } else {
      return []
    }
  },
  // 停權的使用者
  getterForbiddenUsers (state, getters, rootState, rootGetters) {
    if (state.users && state.users.length) {
      return state.users.filter(d => d.status === '停權')
    } else {
      return []
    }
  },
  // 啟用的使用者
  getterActivedUsers (state, getters, rootState, rootGetters) {
    if (state.users && state.users.length) {
      return state.users.filter(d => d.status === '啟用')
    } else {
      return []
    }
  },
  // 單位選單
  getterUnitOptions (state, getters, rootState, rootGetters) {
    let options = []
    if (state.units && state.units.length) {
      options = state.units.map(d => {
        return {
          label: d.unit,
          value: d.unit
        }
      })
    }
    return options
  },
  // 單位選單
  getterRoleOptions (state, getters, rootState, rootGetters) {
    let options = []
    if (state.roles && state.roles.length) {
      options = state.roles.map(d => {
        return {
          label: d.role,
          value: d.role
        }
      })
    }
    return options
  },
  // 單位所在縣市選單
  getterUnitCityOptions (state, getters, rootState, rootGetters) {
    let options = []
    if (state.units && state.units.length) {
      options = state.units.reduce((accumulator, currentValue) => {
        if (accumulator.some(d => d.value === currentValue.county) == false) {
          accumulator.push({
            label: currentValue.county,
            value: currentValue.county
          })
        }
        return accumulator
      }, [])
    }
    return options
  },
  getterFirstCustomKeywordTitle (state, getters, rootState, rootGetters) {
    return state.riskKeywordGroup[0] && state.riskKeywordGroup[0].keyword_group_name
      ? state.riskKeywordGroup[0].keyword_group_name
      : 'DBS CDD負面字串'
  },
  getterFirstCustomKeywords (state, getters, rootState, rootGetters) {
    return state.riskKeywordGroup[0] && state.riskKeywordGroup[0].keywords
      ? state.riskKeywordGroup[0].keywords
      : []
  },
}
