import Cookies from 'js-cookie'

export default {
  mutationIsFullPageLoading (state, payload) {
    state.isFullPageLoading = payload
  },
  mutationIsLogin (state, payload) {
    state.isLogin = payload
    // -- 登出清除瀏覽器token --
    if (!payload) {
      sessionStorage.removeItem('bp__dbs__token')
      sessionStorage.removeItem('bp__dbs__userInfo')
      Cookies.remove('bp__dbs__token', { path: '' })
      Cookies.remove('bp__dbs__userInfo', { path: '' })
    }
  },
  mutationIsLoginKept (state, payload) {
    state.isLoginKept = payload
    // -- 紀錄到瀏覽器 --
    if (state.isLoginKept == true) {
      Cookies.set('bp__dbs__isLoginKept', true, {
        expires: 365,
      })
    } else {
      Cookies.remove('bp__dbs__isLoginKept', { path: '' })
    }
  },
  mutationJwtToken (state, payload) {
    if (!payload) {
      sessionStorage.removeItem('bp__dbs__token')
      Cookies.remove('bp__dbs__token', { path: '' })
      return
    }
    // -- 取出payload --
    let jwtPayload = null
    let arr = payload.split('.')
    let payloadBase64 = arr[1]
    if (payloadBase64) {
      let payloadJson = atob(payloadBase64)
      if (payloadJson) {
        jwtPayload = JSON.parse(payloadJson)
      }
    }
    if (!jwtPayload) {
      sessionStorage.removeItem('bp__dbs__token')
      Cookies.remove('bp__dbs__token')
      return
    }
    // -- 紀錄到state --
    state.jwtToken = payload
    state.jwtTokenPayload = jwtPayload
    // -- 紀錄到瀏覽器 --
    // if (state.isLoginKept == true) {
      Cookies.set('bp__dbs__token', payload, {
        expires: 365,
      })
      sessionStorage.removeItem('bp__dbs__token')
    // } else {
    //   Cookies.remove('bp__dbs__token', { path: '' })
    //   sessionStorage.setItem('bp__dbs__token', payload)
    // }
  },
  mutationIsBtnLoading (state, payload) {
    state.isBtnLoading = payload
  },
  // 原始路由query（未編碼）
  mutationOriginRouteQuery (state, payload) {
    state.originRouteQuery = payload
  },
  mutationRouteHistoryAdd (state, payload) {
    state.routeHistory.push({
      origin: state.originRouteQuery,
      route: payload
    })
  },
  mutationRoles (state, payload) {
    state.roles = payload
  },
  mutationLimits (state, payload) {
    state.limits = payload
  },
  mutationUsers (state, payload) {
    state.users = payload
  },
  mutationUnits (state, payload) {
    state.units = payload
  },
  mutationUserInfo (state, payload) {
    // userInfo
    state.userInfo = payload
    // userLimit
    if (payload && payload.limits) {
      state.userLimits = payload.limits
    }
    // -- 紀錄到瀏覽器 --
    if (state.isLoginKept == true) {
      Cookies.set('bp__dbs__userInfo', payload, {
        expires: 365,
      })
      sessionStorage.removeItem('bp__dbs__userInfo')
    } else {
      Cookies.remove('bp__dbs__userInfo', { path: '' })
      sessionStorage.setItem('bp__dbs__userInfo', payload)
    }
  },
  mutationRiskKeywordGroup (state, payload) {
    state.riskKeywordGroup = payload
  },
  mutationErrorCodes (state, payload) {
    state.errorCodes = payload
  }
  // 公司查詢頁query
  // mutationCompanySearchPageQuery (state, { searchData, advSearchData }) {
    
  // },
}
