import utils from '@/utils/utils.js'
import * as codes from '@/const/codes.ts'

export default {
  getterSearchConditionSummary (state, getters, rootState, rootGetters) {
    let keyword = ''
    let companyCount = state.companyCount
    if (state.currentSearchQuery && state.currentSearchQuery.basic && state.currentSearchQuery.basic.keyword) {
      keyword = `「${state.currentSearchQuery.basic.keyword}」`
    }
    if (companyCount) {
      return `根據您所設定搜尋${keyword}之條件，共獲得 ${utils.formatCommaNumber(companyCount)} 筆資料。`
    } else {
      return `很抱歉您所設定搜尋${keyword}之條件查無結果`
    }
  },
  // 搜尋條件文字描述
  getterSearchConditionList (state, getters, rootState, rootGetters) {
    let textArr = []
    if (state.currentSearchQuery) {
      // -- 基本資料組字串 --
      // 說明：basic內不事先組好文字的原因，是因為基本查詢會有很多不同的頁面送出request，在這個地方做比較容易（只需做一次）
      if (state.currentSearchQuery.basic) {
        // keyword
        if (state.currentSearchQuery.basic.keyword) {
          textArr.push(`關鍵字：${state.currentSearchQuery.basic.keyword}`)
        }
        // 關鍵字類型：
        if (state.currentSearchQuery.basic.keyword_type) {
          textArr.push(`關鍵字類型：${codes.COMPANY_KEYWORD_TYPE_DICT[state.currentSearchQuery.basic.keyword_type]}`)
        }
        // 公司類型
        if (state.currentSearchQuery.basic.data_type) {
          textArr.push(`公司類型：${state.currentSearchQuery.basic.data_type}`)
        }
        // -- 查詢頁的進階設定 --
        // let advTextArr = []
        // Object.keys(state.currentSearchQuery.basic).forEach(d => {
        //   if (codes.FILTER_ITEM_DICT[d]) {
        //     const text = `${codes.FILTER_ITEM_DICT[d]}(${state.currentSearchQuery.basic[d]})`
        //     advTextArr.push(text)
        //   }
        // })
        // if (advTextArr.length) {
        //   const text = advTextArr.join('、')
        //   textArr.push(`進階設定：${text}`)
        // }
      }
      // -- 篩選條件（含查詢頁的進階設定） --
      if (state.currentSearchQuery.filter && state.currentSearchQuery.filter.length) {
        state.currentSearchQuery.filter.forEach(item => {
          if (item._desc) {
            textArr.push(`篩選條件：${item._desc}`)
          }
        })
      }
    }
    return textArr
  }
}
