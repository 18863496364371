import Vue from 'vue'
import Router from 'vue-router'
import middleware from './middleware.js'
import mainRoutes from './mainRoutes.js'
import exceptionRoutes from './exceptionRoutes.js'
import thunderRoutes from './thunderRoutes.js'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL, // @Q@ 範例copy來的不確定做什麼用要研究一下
  routes: (() => {
    // 1.主要路由
    let routes = mainRoutes
    // 2.非正式機才把thunder路由加入
    if (process.env.VUE_APP_MODE !== 'build') {
      routes = routes.concat(thunderRoutes)
    }
    // 3.例外處理路由
    routes = routes.concat(exceptionRoutes)
    return routes
  })(),
  scrollBehavior: function (to) {
    // 換頁時捲軸移到最上面
    if (to.hash) {
      return window.scrollTo({
        top: document.querySelector(to.hash).offsetTop,
        behavior: 'smooth'
      })
    } else {
      return window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
})

router.beforeEach(middleware)

router.onError((error) => {
  const pattern = /router.onError/g
  const isChunkLoadFailed = error.message.match(pattern)
  const targetPath = router.history.pending.fullPath
  if (isChunkLoadFailed) {
    router.replace(targetPath)
  }
})

export default router
