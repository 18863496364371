/* eslint-disable */
import moment from 'moment'

export default {
  getPathName: function () {
    return window.location.pathname;
  },
  // 網址參數轉換為物件
  // 參數格式："?param1=val1&param2=val2"
  // 回傳格式：{ param1: val1, param2: val2 }
  decodeUrlQuery: function (str) {
    str = decodeURIComponent(str)
    if (str.substring(0,1) === '?') {
      str = str.substring(1, str.length)
    }
    var arrString = str.split('&')
    var obj = {} 
    arrString.forEach(function(val) {
      var o = val.split('=')
      var item = {}
      obj[o[0]] = o[1]
    })
    return obj
  },
  returnQueryString (query) {
    let queryString = ''
    if (query) {
      queryString = Object.keys(query).map(key => `${key}=${query[key]}`).join('&')
      if (queryString) {
        queryString = '?' + queryString
      }
    }
    return queryString
  },
  getBrowserType: function () {
    var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串 
    var isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器 
    // var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera; //判断是否IE浏览器 
    var isIE = window.ActiveXObject || "ActiveXObject" in window
    // var isEdge = userAgent.indexOf("Windows NT 6.1; Trident/7.0;") > -1 && !isIE; //判断是否IE的Edge浏览器 
    var isEdge = userAgent.indexOf("Edge") > -1; //判断是否IE的Edge浏览器
    var isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器 
    var isSafari = userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") == -1; //判断是否Safari浏览器 
    var isChrome = userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1 && !isEdge; //判断Chrome浏览器 

    if (isIE) {
      var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
      reIE.test(userAgent);
      var fIEVersion = parseFloat(RegExp["$1"]);
      if (userAgent.indexOf('MSIE 6.0') != -1) {
        return "IE6";
      } else if (fIEVersion == 7) {
        return "IE7";
      } else if (fIEVersion == 8) {
        return "IE8";
      } else if (fIEVersion == 9) {
        return "IE9";
      } else if (fIEVersion == 10) {
        return "IE10";
      } else if (userAgent.toLowerCase().match(/rv:([\d.]+)\) like gecko/)) {
        return "IE11";
      } else {
        return "0"
      } //IE版本过低
    } //isIE end 

    if (isFF) {
      return "FF";
    }
    if (isOpera) {
      return "Opera";
    }
    if (isSafari) {
      return "Safari";
    }
    if (isChrome) {
      return "Chrome";
    }
    if (isEdge) {
      return "Edge";
    }
  },

  numberToString: function (number) {
    if (!number || isNaN(number)) {
      number = "0";
    } else if (number === Infinity || number == "Infinity") {
      number = "∞";
    }
    return number;
  },

  // deep clone
  clone: function (obj) {
    return JSON.parse(JSON.stringify(obj));
  },
  // deep clone two Objects
  extend: function (obj1, obj2) {
    var newObj = JSON.parse(JSON.stringify(obj1));
    Object.keys(obj2).forEach(function(key){
      newObj[key] = obj2[key]
    })
    return newObj;
  },
  // 判斷是否為function
  isFunction: function (fn) {
    return (!!fn && !fn.nodename && fn.constructor != String && fn.constructor != RegExp && fn.constructor != Array && /function/i.test(fn + ""));
  },
  // 省略文章字數
  ellipisText: function (text, limit) {
    if (text && limit) {
      if (text.length > limit) {
        text = text.substring(0, limit - 1) + "..."; // 超過字數以"..."取代
      }
    }
    return text;
  },
  // 加上千分位 ,
  formatCommaNumber: function (num = 0) {
    var parts = num.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  },
  // 格式化日期
  formatDateString: function (dateStr) {
    if (!dateStr) {
      return '-'
    }
    const date = moment(dateStr, 'YYYY年MM月DD日') // 無論是'YYYY年MM月DD日'或'YYYY-MM-DD'的資料都能轉
    const year = date.year()
    let dateString = ''
    // 西元年
    if (year > 1000) {
      return date.formate('YYYY-MM-DD')
    // 民國年
    } else {
      return date.year(year + 1911).formate('YYYY-MM-DD')
    }
  },
  //日期轉字串 
  dateToString: function (date) {
    return date.getFullYear() + '-' + ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
  },
  //yyyy:MM:dd hh:30
  // dateTimeToString: function (date) {
  //   return date.getFullYear() + '-' + ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + " " + date.getHours() + ":30";
  // },
  //日期天數
  dayRange: function (date1, date2) {
    // var timeDiff = Math.abs(date2.getTime() - date1.getTime());
    // var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    const startDate = moment(date1)
    const endDate = moment(date2)
    const diffDays = endDate.diff(startDate, 'days')
    return diffDays + 1;
  },
  //取得當月第一天日期
  getFirstDateOfMonth: function (date) {
    date.setDate(1);
    return date;
  },
  //取得當月最後一天日期
  getLastDateOfMonth: function (current) {
    var currentMonth=current.getMonth()
    var nextMonth=++currentMonth
    var nextMonthDayOne =new Date(current.getFullYear(),nextMonth,1)
    var minusDate=1000*60*60*24
    return new Date(nextMonthDayOne.getTime()-minusDate)
  },
  shortNumberString: function (n) {
    var returnN = "";
    if (n >= 1000 && n < 10000) {
      returnN = (n / 1000).toFixed(1) + "k";
    } else if (n >= 10000) {
      returnN = (n / 1000).toFixed(0) + "k";
    } else {
      returnN = String(n);
    }
    return returnN;
  },
  removeEnterWord: function (str) {
    return str.replace(/\r\n|\n/g, "");
  },
  replaceAll: function (targetText, findText, newText) {
    var result = targetText.replace(new RegExp(findText, 'g'), newText);
    return result;
  },
  countLines(ele) {
    var styles = window.getComputedStyle(ele, null);
    var lh = parseInt(styles.lineHeight, 10);
    var h = parseInt(styles.height, 10);
    var lc = Math.round(h / lh);
    // console.log('line count:', lc, 'line-height:', lh, 'height:', h);
    return lc;
  },
  verifyEmail: function (email) {
    //Regular expression Testing
    var emailRule = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
    //validate ok or not
    if(email.search(emailRule)!= -1){
      return true;
    }else{
      return false;
    }
  },
  scrollToTop: function (to, duration) {
    if (!to) {
      to = 0
    }
    if (!duration) {
      duration = 300
    }
    var start = document.body.scrollTop || document.documentElement.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20

    var animateScroll = function() {
      currentTime += increment;
      var val = Math.easeInOutQuad(currentTime, start, change, duration);
      document.body.scrollTop = val;
      document.documentElement.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    }

    Math.easeInOutQuad = function(t, b, c, d) {
      t /= d / 2;
      if (t < 1) return c / 2 * t * t + b;
      t--;
      return -c / 2 * (t * (t - 2) - 1) + b;
    }
    animateScroll()
  },
  stringifyJsonString: function(json) {
    var jsonString = ''
    if (json != null) {
      var replacer = function(key, value) {
        // 因function無法stringify所以轉換為提示文字
        if (typeof (value) === 'function') {
          return '>>>>>>>>>>>>>> function無法解析'
        }
        return value
      }
      jsonString = JSON.stringify(json, replacer, 2)
    }
    return jsonString
  },
  getRandomText (length = 3) {
    return Math.random().toString(36).substr(2, length) // 英文+數字
  },
  // 查詢關鍵字數量
  searchKeywordCount (content, keyword) {
    const reg = new RegExp(keyword,"g")
    const c = content.match(reg)
    return c?c.length:0
  },
  // 匯出圖片
  exportImage (targetId, fileName, callback) {
    let html2canvas_options = {
        scale: 2,
        logging: true,
        profile: true,
        useCORS: true
      }
    var downloadname = fileName

    html2canvas(
      document.querySelector("#" + targetId),
      html2canvas_options
    ).then(function(canvas) {
      download(canvas, downloadname);
      if (callback) {
        callback()
      }
    })

    // 下載圖片
    function download(canvas, downloadname) {
      var a = document.createElement("a");
      var blob = dataURLtoBlob(canvas.toDataURL());
      blobToDataURL(blob, function(dataurl) {});
      var objurl = URL.createObjectURL(blob);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, downloadname + ".png");
      } else {
        var objurl = URL.createObjectURL(blob);
        a.href = objurl;
        a.download = downloadname;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }

    // dataURL to blob
    function dataURLtoBlob(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    }

    // blob to dataURL
    function blobToDataURL(blob, callback) {
      var a = new FileReader();
      a.onload = function(e) {
        callback(e.target.result);
      };
      a.readAsDataURL(blob);
    }
  }
};

Date.prototype.addDays = function (days) {
	this.setDate(this.getDate() + days);
	return this;
}
/* eslint-enable */
