import { Notification, MessageBox, Message } from 'element-ui'
import searchApiService from './searchApiService' // 查詢api service
import crawlerApiService from './crawlerApiService' // 查詢api service
import publicApiService from './publicApiService' // 帳號api service
import * as apiInterfaces from '@/interfaces/apiInterfaces'
import axios from 'axios'

const successNotify = (title:string) => {
  (Notification as any).success({
    title
  })
}

const successMessage = (message:string) => {
  Message.success({
    message
  })
}

// ---------- 帳號 ----------
// 取得使用者資訊
export function user (params:apiInterfaces.IUserRequest):Promise<apiInterfaces.IUserResponse> {
  return publicApiService({
    url: '/api/v1/user',
    method: 'get',
    params
  }).then(d => d).catch(d => d)
}
// 取得使用者資訊
export function users ():Promise<apiInterfaces.IUsersResponse> {
  return publicApiService({
    url: '/api/v1/users',
    method: 'get'
  }).then(d => d).catch(d => d)
}

// 個人註冊
export function userRegister (data:apiInterfaces.IUserRegisterRequest):Promise<apiInterfaces.IUserRegisterResponse> {
  return publicApiService({
    url: '/api/v1/user.register',
    method: 'post',
    data
  }).then(d => {
    successMessage('註冊成功')
  }).catch(d => d)
}
// 啟用個人註冊
export function userActive (data:apiInterfaces.IUserActiveRequest):Promise<apiInterfaces.IUserActiveResponse> {
  return publicApiService({
    url: '/api/v1/user.active',
    method: 'post',
    data
  }).then(d => {
    successMessage('啟用成功')
  }).catch(d => d)
}
// 恢復啟用個人註冊
export function userReactive (data:apiInterfaces.IUserReactiveRequest):Promise<apiInterfaces.IUserReactiveResponse> {
  return publicApiService({
    url: '/api/v1/user.reactive',
    method: 'post',
    data
  }).then(d => {
    successMessage('啟用成功')
  }).catch(d => d)
}
// 停權使用者
export function userForbid (data:apiInterfaces.IUserForbidRequest):Promise<apiInterfaces.IUserForbidResponse> {
  return publicApiService({
    url: '/api/v1/user.forbid',
    method: 'post',
    data
  }).then(d => {
    successMessage('停權成功')
  }).catch(d => d)
}
// 修改密碼
export function userPasswordReset (data:apiInterfaces.IUserPasswordResetRequest):Promise<apiInterfaces.IUserPasswordResetResponse> {
  return publicApiService({
    url: '/api/v1/user-password.reset',
    method: 'post',
    data
  }).then(d => {
    successMessage('修改密碼成功')
  }).catch(d => d)
}
// 新增使用者
export function userAdd (data:apiInterfaces.IUserAddRequest):Promise<apiInterfaces.IUserAddResponse> {
  return publicApiService({
    url: '/api/v1/user.add',
    method: 'post',
    data
  }).then(d => {
    successMessage('新增成功')
  }).catch(d => d)
}
// 編輯使用者
export function userEdit (data:apiInterfaces.IUserEditRequest):Promise<apiInterfaces.IUserEditRequest> {
  return publicApiService({
    url: '/api/v1/user.edit',
    method: 'post',
    data
  }).then(d => {
    successMessage('修改成功')
  }).catch(d => d)
}
// 刪除使用者
export function userDelete (data:apiInterfaces.IUserDeleteRequest):Promise<apiInterfaces.IUserDeleteResponse> {
  return publicApiService({
    url: '/api/v1/user.delete',
    method: 'post',
    data
  }).then(d => {
    successMessage('刪除成功')
  }).catch(d => d)
}
// 批次使用者上傳
export function userImport (data:apiInterfaces.IUserImportRequest):Promise<apiInterfaces.IUserImportResponse> {
  return publicApiService({
    url: '/api/v1/user.import',
    method: 'post',
    data
  }).then(d => {
    successMessage('上傳成功')
  }).catch(d => d)
}
// 忘記密碼,重寄亂數密碼
export function userForgetPassword (data:apiInterfaces.IUserForgetPasswordRequest):Promise<apiInterfaces.IUserForbidResponse> {
  return publicApiService({
    url: '/api/v1/user.forget-password',
    method: 'post',
    data
  }).then(d => {
    successMessage('已送出email請至信箱收取')
    return d
  }).catch(d => d)
}
// 登入
export function userLogin (data:apiInterfaces.IUserLoginRequest):Promise<apiInterfaces.IUserLoginResponse> {
  return publicApiService({
    url: '/api/v1/user.login',
    method: 'post',
    data
  }).then((d: any) => {
    successNotify(`HI,${d.payload.user_info.name}`)
    return d
  }).catch(d => d)
}
// 登出
export function userLogout ():Promise<apiInterfaces.IUserLoginResponse> {
  return publicApiService({
    url: '/api/v1/user.logout',
    method: 'post'
  }).then(d => d).catch(d => d)
}
// 取得session
export function userSession ():Promise<apiInterfaces.IUserSessionResponse> {
  return publicApiService({
    url: '/api/v1/user-session',
    method: 'post'
  }).then(d => d).catch(d => d)
}

// 取得session-id
export function userSessionID ():Promise<apiInterfaces.IUserSessionIDResponse> {
  return publicApiService({
    url: '/api/v1/user-session-id',
    method: 'post'
  }).then(d => d).catch(d => d)
}

// 標籤
export function companyEditLabel (data: any):Promise<apiInterfaces.IUserSessionIDResponse> {
  return searchApiService({
    url: '/search2/api/v1/company.editlabel',
    method: 'post',
    data
  }).then(d => d).catch(d => d)
}

// ---------- 單位管理 ----------
// 取得單位資訊
export function unit (params:apiInterfaces.IUnitRequest):Promise<apiInterfaces.IUnitResponse> {
  return publicApiService({
    url: '/api/v1/unit',
    method: 'get',
    params
  }).then(d => d).catch(d => d)
}
// 取得單位列表資訊
export function units () {
  return publicApiService({
    url: '/api/v1/units',
    method: 'get'
  }).then(d => d).catch(d => d)
}
// 新增單位
export function unitAdd (data:apiInterfaces.IUnitAddRequest):Promise<apiInterfaces.IUnitAddResponse> {
  return publicApiService({
    url: '/api/v1/unit.add',
    method: 'post',
    data
  }).then(d => {
    successMessage('新增成功')
    return d
  }).catch(d => d)
}
// 編輯單位
export function unitEdit (data:apiInterfaces.IUnitEditRequest):Promise<apiInterfaces.IUnitEditResponse> {
  return publicApiService({
    url: '/api/v1/unit.edit',
    method: 'post',
    data
  }).then(d => {
    successMessage('修改成功')
    return d
  }).catch(d => d)
}
// 刪除單位
export function unitDelete (data:apiInterfaces.IUnitDeleteRequest):Promise<apiInterfaces.IUnitDeleteResponse> {
  return publicApiService({
    url: '/api/v1/unit.delete',
    method: 'post',
    data
  }).then(d => {
    successMessage('刪除成功')
    return d
  }).catch(d => d)
}
// 批次單位上傳
export function unitImport (data:apiInterfaces.IUnitImportRequest):Promise<apiInterfaces.IUnitImportResponse> {
  return publicApiService({
    url: '/api/v1/unit.import',
    method: 'post',
    data
  }).then(d => {
    successMessage('上傳成功')
    return d
  }).catch(d => d)
}
// ---------- 角色權限 ----------
// 取得單位列表資訊
export function roles () {
  return publicApiService({
    url: '/api/v1/roles',
    method: 'get'
  }).then(d => d).catch(d => d)
}
// 取得角色與權限
export function role (params:apiInterfaces.IRoleRequest):Promise<apiInterfaces.IReportAddResponse> {
  return publicApiService({
    url: '/api/v1/role',
    method: 'get',
    params
  }).then(d => d).catch(d => d)
}
// 新增角色
export function roleAdd (data:apiInterfaces.IRoleAddRequest):Promise<apiInterfaces.IRoleAddResponse> {
  return publicApiService({
    url: '/api/v1/role.add',
    method: 'post',
    data
  }).then(d => {
    successMessage('新增成功')
    return d
  }).catch(d => d)
}
// 編輯角色與權限
export function roleEdit (data:apiInterfaces.IRoleEditRequest):Promise<apiInterfaces.IRoleEditResponse> {
  return publicApiService({
    url: '/api/v1/role.edit',
    method: 'post',
    data
  }).then(d => {
    successMessage('修改成功')
    return d
  }).catch(d => d)
}
// 刪除角色
export function roleDelete (data:apiInterfaces.IRoleDeleteRequest):Promise<apiInterfaces.IRoleDeleteResponse> {
  return publicApiService({
    url: '/api/v1/role.delete',
    method: 'post',
    data
  }).then(d => {
    successMessage('刪除成功')
    return d
  }).catch(d => d)
}
// 取得權限列表
export function limits ():Promise<apiInterfaces.ILimitsResponse> {
  return publicApiService({
    url: '/api/v1/limits',
    method: 'get'
  }).then(d => d).catch(d => d)
}
// 新增權限
export function limitAdd (data:apiInterfaces.ILimitAddRequest):Promise<apiInterfaces.ILimitAddResponse> {
  return publicApiService({
    url: '/api/v1/limit.add',
    method: 'post',
    data
  }).then(d => {
    successMessage('新增成功')
    return d
  }).catch(d => d)
}
// ---------- 收藏 ----------
// 取得收藏列表
export function collections (params:apiInterfaces.ICollectionsRequest):Promise<apiInterfaces.ICollectionsResponse> {
  return publicApiService({
    url: '/api/v1/collections',
    method: 'get',
    params
  }).then(d => d).catch(d => d)
}
// 查詢公司是否收藏
export function isCollection (params:apiInterfaces.IIsCollectionRequest):Promise<apiInterfaces.IIsCollectionResponse> {
  return publicApiService({
    url: '/api/v1/is-collection',
    method: 'get',
    params
  }).then(d => d).catch(d => d)
}
// 查詢複數公司是否收藏
export function isCollections (data:apiInterfaces.IIsCollectionsRequest):Promise<apiInterfaces.IIsCollectionsResponse> {
  return publicApiService({
    url: '/api/v1/is-collections',
    method: 'post',
    data
  }).then(d => d).catch(d => d)
}
// 收藏公司
export function collectionAdd (data:apiInterfaces.ICollectionAddRequest):Promise<apiInterfaces.ICollectionAddResponse> {
  return publicApiService({
    url: '/api/v1/collection.add',
    method: 'post',
    data
  }).then(d => {
    successMessage('已加入收藏')
    return d
  }).catch(d => d)
}
// 收藏複數公司
export function collectionsAdd (data:apiInterfaces.ICollectionsAddRequest):Promise<apiInterfaces.ICollectionsAddResponse> {
  return publicApiService({
    url: '/api/v1/collections.add',
    method: 'post',
    data
  }).then(d => {
    successMessage('已加入收藏')
    return d
  }).catch(d => d)
}
// 刪除複數公司
export function collectionsDelete (data:apiInterfaces.ICollectionsDeleteRequest):Promise<apiInterfaces.ICollectionsDeleteResponse> {
  return publicApiService({
    url: '/api/v1/collections.delete',
    method: 'post',
    data
  }).then(d => {
    successMessage('已移除收藏')
    return d
  }).catch(d => d)
}
// 編輯複數公司
export function collectionsEdit (data:apiInterfaces.ICollectionsEditRequest):Promise<apiInterfaces.ICollectionsEditResponse> {
  return publicApiService({
    url: '/api/v1/collections.edit',
    method: 'post',
    data
  }).then(d => {
    successMessage('編輯成功')
    return d
  }).catch(d => d)
}
// ---------- 自訂關鍵字 ----------
// 取得個人風險關鍵字群組
export function riskKeywordGroup ():Promise<apiInterfaces.IRiskKeywordGroupResponse> {
  return publicApiService({
    url: '/api/v1/risk-keyword-group',
    method: 'get'
  }).then(d => d).catch(d => d)
}
// 新增個人風險關鍵字群組
export function riskKeywordGroupAdd (data: apiInterfaces.IRiskKeywordGroupAddRequest):Promise<apiInterfaces.IRiskKeywordGroupAddResponse> {
  return publicApiService({
    url: '/api/v1/risk-keyword-group.add',
    method: 'post',
    data
  }).then(d => d).catch(d => d)
}
// 刪除個人風險關鍵字群組
export function riskKeywordGroupDelete (data: apiInterfaces.IRiskKeywordGroupDeleteRequest):Promise<apiInterfaces.IRiskKeywordGroupDeleteResponse> {
  return publicApiService({
    url: '/api/v1/risk-keyword-group.delete',
    method: 'post',
    data
  }).then(d => d).catch(d => d)
}
// 編輯個人風險關鍵字群組
export function riskKeywordGroupEdit (data: apiInterfaces.IRiskKeywordGroupEditRequest):Promise<apiInterfaces.IRiskKeywordGroupEditResponse> {
  return publicApiService({
    url: '/api/v1/risk-keyword-group.edit',
    method: 'post',
    data
  }).then(d => d).catch(d => d)
}
// ---------- 其他 ----------
// 取得錯誤訊息對照表
export function errorCodes ():Promise<apiInterfaces.IErrorcodesResponse> {
  return publicApiService({
    url: '/api/v1/errorcodes',
    method: 'get'
  }).then(d => d).catch(d => d)
}
// 問題回報
export function reportAdd (data:apiInterfaces.IReportAddRequest):Promise<apiInterfaces.IReportAddResponse> {
  return publicApiService({
    url: '/api/v1/report.add',
    method: 'post',
    data
  }).then(d => {
    successMessage('送出成功')
    return d
  }).catch(d => d)
}
// 問題回報
export function reports (data:apiInterfaces.IReportsRequest):Promise<apiInterfaces.IReportsRequest> {
  return publicApiService({
    url: '/api/v1/reports',
    method: 'get',
    data
  }).then(d => d).catch(d => d)
}
// ---------- 查詢 ----------
// 公司列表
export function companyListGet (data:apiInterfaces.ICompanyListGetRequest):Promise<apiInterfaces.ICompanyListGetResponse> {
  return searchApiService({
    url: '/search2/api/v1/company-list.get',
    method: 'post',
    data
  }).then(d => {
    return d
  }).catch(d => d)
}
// 公司資料
export function companyGet (data:apiInterfaces.ICompanyListGetRequest):Promise<apiInterfaces.ICompanyGetResponse> {
  return searchApiService({
    url: '/search2/api/v1/company.get',
    method: 'post',
    data
  }).then(d => d).catch(d => d)
}
// 人名資料查詢
export function namesGet (data:apiInterfaces.INamesGetRequest):Promise<apiInterfaces.INamesGetResponse> {
  return searchApiService({
    url: '/search2/api/v1/names.get',
    method: 'post',
    data
  }).then(d => d).catch(d => d)
}
// 比較人物、比較公司查詢
export function searchGet (data:apiInterfaces.ISearchGetRequest):Promise<apiInterfaces.ISearchGetResponse> {
  return searchApiService({
    url: '/search2/api/v1/search.get',
    method: 'post',
    data
  }).then(d => d).catch(d => d)
}
// 判決書內容
export function newsGet (data:apiInterfaces.IVerdictsGetRequest):Promise<apiInterfaces.IVerdictsGetResponse> {
  return searchApiService({
    url: '/search2/api/v1/news.get',
    method: 'post',
    data
  }).then((d: any) => {
    if (!d.payload ||
    !d.payload.found ||
    !d.payload.source ||
    !d.payload.source.content) {
      Message({
        message: '無新聞資料',
        type: 'error',
        duration: 5 * 1000
      })
    }
    return d
  }).catch(d => d)
}
// 判決書內容
export function verdictsGet (data:apiInterfaces.IVerdictsGetRequest):Promise<apiInterfaces.IVerdictsGetResponse> {
  return searchApiService({
    url: '/search2/api/v1/verdicts.get',
    method: 'post',
    data
  }).then((d: any) => {
    if (!d.payload ||
      !d.payload.found ||
      !d.payload.source ||
      !d.payload.source.content) {
        Message({
          message: '無判決書資料',
          type: 'error',
          duration: 5 * 1000
        })
      }
    return d
  }).catch(d => d)
}
// 公司（查詢的）及分公司（自己的）地圖座標
export function companyGetLocation (data: any) {
  return crawlerApiService({
    url: '/crawler/api/company/company.getLocation',
    method: 'post',
    data
  }).then(d => d).catch(d => d)
}
// 地址翻譯
export function addressTranslate (data: any) {
  return crawlerApiService({
    url: '/crawler/api/company/address.translate',
    method: 'post',
    data
  }).then(d => d).catch(d => d)
}
// 地址批次翻譯
export function addressTranslateBatch (data: any) {
  return crawlerApiService({
    url: '/crawler/api/company/address.translateBatch',
    method: 'post',
    data
  }).then(d => d).catch(d => d)
}

// go (暫)
// export function go (data: any) {
//   return axios({
//     url: 'http://192.168.1.165:5678/go',
//     method: 'post',
//     data
//   }).then(d => d.data).catch(d => d)
// }
// 關係人地圖圖表
export function graphForDbs (data: any) {
  return searchApiService({
    url: '/search2/api/v1/graph_for_dbs',
    method: 'post',
    data
  }).then(d => d).catch(d => d)
}
// 關係人地圖圖表
export function graphForDbsOld (data: any) {
  return searchApiService({
    url: '/search2/api/v1/graph_for_dbs_old',
    method: 'post',
    data
  }).then(d => d).catch(d => d)
}
// ---------- 網址 ----------
// 網址字串編碼
export function encode (data:apiInterfaces.IEncodeRequest):Promise<apiInterfaces.IEncodeResponse> {
  return searchApiService({
    url: '/search2/api/v1/encode',
    method: 'post',
    data
  }).then(d => d).catch(d => d)
}
// 網址字串解碼
export function decode (data:apiInterfaces.IDecodeRequest):Promise<apiInterfaces.IDecodeResponse> {
  return searchApiService({
    url: '/search2/api/v1/decode',
    method: 'post',
    data
  }).then(d => d).catch(d => d)
}
