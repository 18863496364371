export default [
  {
    index: 0,
    label: '0',
    value: 0
  },
  {
    index: 1,
    label: '50萬美元',
    value: 500000
  },
  {
    index: 2,
    label: '1百萬美元',
    value: 1000000
  },
  {
    index: 3,
    label: '2百萬美元',
    value: 2000000
  },
  {
    index: 4,
    label: '3百萬美元',
    value: 3000000
  },
  {
    index: 5,
    label: '4百萬美元',
    value: 4000000
  },
  {
    index: 6,
    label: '5百萬美元',
    value: 5000000
  },
  {
    index: 7,
    label: '6百萬美元',
    value: 6000000
  },
  {
    index: 8,
    label: '7百萬美元',
    value: 7000000
  },
  {
    index: 9,
    label: '8百萬美元',
    value: 8000000
  },
  {
    index: 10,
    label: '9百萬美元',
    value: 9000000
  },
  {
    index: 11,
    label: '1千萬美元',
    value: 10000000
  },
  {
    index: 12,
    label: '1千萬美元以上',
    value: 9999999999999
  }
]
