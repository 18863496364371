export default {
  isFullPageLoading: false, // 整頁的loading圖示
  isLogin: false,
  isLoginKept: false,
  jwtToken: null, // jwt字串
  jwtTokenPayload: null, // jwt的payload object
  originRouteQuery: '', // 原始路由query（未編碼）
  userInfo: {},
  userLimits: {}, // 權限 (userInfo.limits)
  roles: [],
  limits: [],
  users: [],
  units: [],
  riskKeywordGroup: [],
  isBtnLoading: false, // 共用的按鈕loading狀態
  routeHistory: [], // 路由歷史紀錄
  errorCodes: {}
  // query: {} // 網址query
}
