export default {
  // getterShareholdersCount (state, getters, rootState, rootGetters) {
  //   if (state.basicData && state.basicData.shareholders && state.basicData.shareholders.stat) {
  //     return state.basicData.shareholders.stat['total-count'] || 0
  //   } else {
  //     return 0
  //   }
  // },
  // getterVerdictsCount (state, getters, rootState, rootGetters) {
  //   if (state.basicData && state.basicData.shareholders && state.basicData.verdicts.stat) {
  //     return state.basicData.verdicts.stat['total-count'] || 0
  //   } else {
  //     return 0
  //   }
  // },
  // 公司類型
  getterStockType (state, getters, rootState, rootGetters) {
    if (state.companyData.basic && state.companyData.basic.stock_type ) {
      return state.companyData.basic.stock_type
    }
    return ''
  },
  getterFirstCustomKeywordQueries (state, getters, rootState, rootGetters) {
    return state.companyNews && state.companyNews.cls.custom && state.companyNews.cls.custom[rootGetters.getterFirstCustomKeywordTitle]
      ? state.companyNews.cls.custom[rootGetters.getterFirstCustomKeywordTitle]
        .map(d => d.queries)
      : []
  },
  getterIsCompanyPublic (state, getters, rootState, rootGetters) {
    return state.companyData.basic && state.companyData.basic.public_issue
      ? true
      : false
  },
}
