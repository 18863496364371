export default {
  mutationBasicList (state, payload) {
    state.basicList = payload
  },
  mutationGetList (state, payload) {
    state.getList = payload
    // -- 顯示區塊 --
    let isShowCompanys = false
    let isShowVerdicts = false
    let isShowNews = false
    payload.forEach(d => {
      if (d === 'companys') {
        isShowCompanys = true
      } else if (d === 'verdicts') {
        isShowVerdicts = true
      } else if (d === 'news') {
        isShowNews = true
      }
    })
    state.isShowCompanys = isShowCompanys
    state.isShowVerdicts = isShowVerdicts
    state.isShowNews = isShowNews
  },
  mutationIsPersonDataLoading (state, payload) {
    state.isPersonDataLoading = payload
  },
  mutationIsPersonNewsLoading (state, payload) {
    state.isPersonNewsLoading = payload
  },
  mutationCompanyList (state, payload) {
    state.companyList = payload
  },
  mutationMultiPersonNews (state, payload) {
    state.multiPersonNews = payload
  },
  mutationMultiPersonData (state, payload) {
    state.multiPersonData = payload
  },
}
