import * as apis from '@/apis/index.ts'

export default {
  // 開啟頁面初次查詢
  async actionInitSearchPage ({ dispatch, commit, state, rootState }, searchRequest) {
    commit('mutationIsPageLoading', true)
    commit('mutationCompanyList', [])
    commit('mutationCompanyCount', 0)
    commit('mutationOptions', [])
    
    searchRequest.basic.get = ['list', 'cls']

    // -- 建立data_type選單資料（選項 公司/商業登記 是否顯示） --
    let defaultDataType = null
    let checkRequest = JSON.parse(JSON.stringify(searchRequest))
    checkRequest.basic.get = ['list']
    // checkRequest.basic['page-size'] = 1
    if (checkRequest.basic.keyword_type === 'all' ||
    checkRequest.basic.keyword_type === 'owner') {
      // 查詢data_type「公司」是否有資料
      checkRequest.basic.data_type = '公司'
      checkRequest.basic.fetch_less_fields = 1
      let promiseCompanyData = apis.companyListGet(checkRequest)
      // 查詢data_type「商業登記」是否有資料
      let checkRequest2 = JSON.parse(JSON.stringify(checkRequest))
      checkRequest2.basic.data_type = '商業登記'
      let promiseRegistrationData = apis.companyListGet(checkRequest2)
      defaultDataType = await Promise.all([promiseCompanyData, promiseRegistrationData])
        .then(d => {
          let sidebarMenu = []
          if (d[0].payload.list && d[0].payload.list.length) {
            sidebarMenu.push('公司')
          }
          if (d[1].payload.list && d[1].payload.list.length) {
            sidebarMenu.push('商業登記')
          }
          commit('mutationSidebarMenu', sidebarMenu)
          return sidebarMenu[0]
        })
        .catch(e => {
          return null
        })
    } else if (checkRequest.basic.keyword_type === 'company') {
      checkRequest.basic.data_type = '公司'
      checkRequest.basic.fetch_less_fields = 1
      defaultDataType = await apis.companyListGet(checkRequest)
        .then(d => {
          if (d.payload.list && d.payload.list.length) {
            commit('mutationSidebarMenu', ['公司'])
            return '公司'
          } else {
            commit('mutationSidebarMenu', [])
            return null
          }
        })
        .catch(e => {
          return null
        })
    } else if (checkRequest.basic.keyword_type === 'registraction') {
      checkRequest.basic.data_type = '商業登記'
      checkRequest.basic.fetch_less_fields = 1
      defaultDataType = await apis.companyListGet(checkRequest)
        .then(d => {
          if (d.payload.list && d.payload.list.length) {
            commit('mutationSidebarMenu', ['商業登記'])
            return '商業登記'
          } else {
            commit('mutationSidebarMenu', [])
            return null
          }
        })
        .catch(e => {
          return null
        })
    } else if (checkRequest.basic.keyword_type === 'tax_number') {
      defaultDataType = await apis.companyListGet(checkRequest)
        .then(d => {
          if (d.payload.list && d.payload.list[0] && d.payload.list[0].main_type) {
            commit('mutationSidebarMenu', [d.payload.list[0].main_type])
            return d.payload.list[0].main_type
          } else {
            commit('mutationSidebarMenu', [])
            return null
          }
        })
        .catch(e => {
          return null
        })
    }

    // -- 將首頁設定的查詢條件紀錄起來 --
    searchRequest.basic.data_type = defaultDataType // 取得目前查詢的data_type
    // commit('mutationOriginSearchQuery', searchRequest)
    commit('mutationCurrentSearchQuery', searchRequest)
    // let filterQueryString = ''
    // if (searchRequest.filter) {
    //   filterQueryString = JSON.stringify(searchRequest.filter)
    // }
    // commit('mutationCurrentFilterQueryString', filterQueryString)
    // -- 查無結果 --
    if (!defaultDataType) {
      commit('mutationCompanyList', [])
      commit('mutationCompanyCount', 0)
      commit('mutationOptions', [])
      commit('mutationIsPageLoading', false)
      return
    }
    // -- 查詢 --
    // 公司列表查詢
    const companyListResult = await apis.companyListGet(searchRequest)
      .then((data) => {
        commit('mutationCompanyList', data.payload.list || [])
        commit('mutationCompanyCount', data.payload.stat['total-count'])
        commit('mutationOptions', data.payload.cls || [])
        return true
      })
      .catch(e => {
        return false
      })
    commit('mutationIsPageLoading', false)
    // 是否為收藏查詢（不等）
    const uniIDs = state.companyList.map(d => d.uniID)
    const isCollectionsRequest = {
      "email": rootState.userInfo.email,
      "companys": uniIDs
    }
    const isCollectionsResult = apis.isCollections(isCollectionsRequest)
      .then((data) => {
        commit('mutationIsCollections', data.payload || {})
      })

    // const result = await Promise.all([companyListResult, isCollectionsResult])
    //   .then(data => true)
    //   .catch(e => false)
    
    return companyListResult
  },
  // 列表&後分類查詢
  async actionSearchPage ({ dispatch, commit, state }, searchRequest) {
    // 首頁的查詢條件疊加上後分類篩選條件
    // let searchRequest = JSON.parse(JSON.stringify(originQuery))
    // if (aditionFilter) {
    //   if (!searchRequest.filter) {
    //     searchRequest.filter = []
    //   }
    //   searchRequest.filter = originQuery.filter.concat(aditionFilter)
    // }

    // 目前的篩選條件字串
    // let filterQueryString = ''
    // if (searchRequest.filter) {
    //   filterQueryString = JSON.stringify(searchRequest.filter)
    // }

    // 決定要查詢的內容
    let get = ['list', 'cls']
    // if (filterQueryString === state.currentFilterQueryString) {
    //   get = ['list'] // 如篩選條件和上次相同，則不查詢後分類
    // }
    searchRequest.basic.get = get

    // 紀錄目前查詢條件
    commit('mutationCurrentSearchQuery', searchRequest)
    // commit('mutationCurrentFilterQueryString', filterQueryString)

    // loading圖示
    // if (get.length === 2) {
    //   commit('mutationIsPageLoading', true)
    // } else {
    //   commit('mutationIsCompanyListLoading', true)
    // }
    commit('mutationIsPageLoading', true)

    // 開始查詢
    let result = await apis.companyListGet(searchRequest)
      .then((data) => {
        commit('mutationCompanyList', data.payload.list || [])
        commit('mutationCompanyCount', data.payload.stat['total-count'])
        commit('mutationOptions', data.payload.cls || [])
        return true
      })
      .catch(e => {
        return false
      })
    commit('mutationIsPageLoading', false)
    // commit('mutationIsCompanyListLoading', false)
    return result
  },
  // 列表
  async actionCompanyList ({ dispatch, commit, state }, searchRequest) {
    commit('mutationIsCompanyListLoading', true)
    commit('mutationCurrentSearchQuery', searchRequest)
    searchRequest.basic.get = ['list']
    let result = await apis.companyListGet(searchRequest)
      .then((data) => {
        commit('mutationCompanyList', data.payload.list || [])
        commit('mutationCompanyCount', data.payload.stat['total-count'])
        return true
      })
      .catch(e => {
        return false
      })
    commit('mutationIsCompanyListLoading', false)
    return result
  }
}
