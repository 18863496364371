import * as apis from '@/apis/index.ts'

export default {
  // 所有資料（取得除了news以外的所有資料）
  async actionMultiPersonDetail ({ dispatch, commit, state }, { basicList, get }) {
    let promiseList = []
    basicList.forEach(d => {
      const searchRequest = {
        get,
        basic: d
      }
      promiseList.push(apis.searchGet(searchRequest))
    })

    commit('mutationIsPersonDataLoading', true)
    let result = await Promise.all(promiseList)
      .then(dataArr => {
        commit('mutationIsPersonDataLoading', false)
        // 所有資料
        const multiPersonData = dataArr.map(d => d.payload)
        commit('mutationMultiPersonData', multiPersonData)
        return true
      })
      .catch(e => {
        return false
      })
    return result
  },
  // 單獨取新聞資料
  async actionMultiPersonNews ({ dispatch, commit, state }, basicList) {
    let promiseList = []
    basicList.forEach(d => {
      const searchRequest = {
        get: {
          news: {
            // 'remove-duplicate': 1
          }
        },
        basic: d
      }
      promiseList.push(apis.searchGet(searchRequest))
    })

    commit('mutationIsPersonNewsLoading', true)
    Promise.all(promiseList)
      .then(dataArr => {
        commit('mutationIsPersonNewsLoading', false)
        // 所有公司資料
        const multiPersonNews = dataArr.map(d => d.payload.news)
        commit('mutationMultiPersonNews', multiPersonNews)
      })
      .catch(e => {
      })
  },
}