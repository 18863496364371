import getters from './getters.js'
import state from './state.js'
import mutations from './mutations.js'
import actions from './actions.js'

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
}
