/*
 * 數字千分位符號
 */

import Vue from 'vue'

Vue.filter('numberWithComma', function (num) {
  if (isNaN(num) == true) {
    return num
  }
  var parts = num.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
})
