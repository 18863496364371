export default {
  basicList: [],
  getList: [], // 查詢'get'的參數key轉換為陣列
  // companyList: [], // 查詢公司清單
  multiPersonData: [], // 全部公司的全部的資料
  multiPersonNews: [], // 全部公司的news資料
  isPersonDataLoading: true,
  isPersonNewsLoading: true,
  isShowCompanys: false,
  isShowVerdicts: false,
  isShowNews: false
}
