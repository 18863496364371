export const SELF_UNIID = '53017509'

// 預設公司列表排序
export const DEFAULT_COMPANY_LIST_SORT_VALUE = '{"key":"capital_amount","order":"desc"}'

// 後分類選單標題名稱（字典）
// export const FILTER_TYPE_TITLE_DIST = {
//   capital_amount: '資本總額',
//   company_state: '公司狀態',
//   data_type: '公司類型',
//   company_operate: '',
//   company_county: '所在地區'
// }

// export const DATA_TYPE_DIST = {
//   company: '公司',
//   business: '商業登記'
// }

// 預設的data_type索引
export const DEFAULT_DATA_TYPE = '公司'

// data_type的索引（作為網址）
export const DATA_TYPE_INDEX_DIST = {
  公司: 'company',
  商業登記: 'business'
}

// 進出口實績代碼對應資料
// 說明：
// 1.匯製圖表時使用chartValue，查詢時使用min和max，value是從新展過來的目前沒用到）
// 2.因為是從新展過來的表修正的，代碼"0"不曉得有什麼意義先保留
export const IMPORT_AND_EXPORT_DATA = {
  A: { label: '10 百萬美元(含)以上', chartValue: 10,value: 10000000, min: 10000000, max: 9999999999999 },
  B: { label: '9-10 百萬美元', chartValue: 9.5, value: 10000000, min: 9000000, max: 10000000 },
  C: { label: '8-9 百萬美元', chartValue: 8.5, value: 9000000, min: 8000000, max: 9000000 },
  D: { label: '7-8 百萬美元', chartValue: 7.5, value: 8000000, min: 7000000, max: 8000000 },
  E: { label: '6-7 百萬美元', chartValue: 6.5, value: 7000000, min: 6000000, max: 7000000 },
  F: { label: '5-6 百萬美元', chartValue: 5.5, value: 6000000, min: 5000000, max: 6000000 },
  G: { label: '4-5 百萬美元', chartValue: 4.5, value: 5000000, min: 4000000, max: 5000000 },
  H: { label: '3-4 百萬美元', chartValue: 3.5, value: 4000000, min: 3000000, max: 4000000 },
  I: { label: '2-3 百萬美元', chartValue: 2.5, value: 3000000, min: 2000000, max: 3000000 },
  J: { label: '1-2 百萬美元', chartValue: 1.5, value: 2000000, min: 1000000, max: 2000000 },
  K: { label: '0.5-1 百萬美元', chartValue: 0.75, value: 1000000, min: 500000, max: 1000000 },
  L: { label: '0-0.5 百萬美元', chartValue: 0.25, value: 500000, min: 0, max: 500000 },
  M: { label: '0', chartValue: 0, value: 0, min: 0, max: 0 },
  0: { label: '0', chartValue: 0, value: 0, min: 0, max: 0 }
}

export const PATENTS_AREA_DIST = {
  TW: '台灣',
  CN: '中國大陸',
  JP: '日本'
}

export const COMPANY_KEYWORD_TYPE_DICT = {
  all: '全部',
  company: '公司',
  registraction: '商業登記',
  owner: '人名',
  tax_number: '統一編號'
}

export const COMPANY_ITEM_MAP = {
  description: {
    name: '公司簡介',
    searchKey: 'basic'
  },
  basic: {
    name: '商工登記資料',
    searchKey: 'basic'
  },
  shareholders: {
    name: '董監事資料',
    searchKey: 'shareholders'
  },
  managers: {
    name: '經理人資料',
    searchKey: 'managers'
  },
  companys_history: {
    name: '變更紀錄',
    searchKey: 'companys_history'
  },
  company_branchs: {
    name: '分公司資料',
    searchKey: 'company_branchs'
  },
  登記地址近似公司: {
    name: '登記地址近似公司',
    searchKey: '登記地址近似公司'
  },
  factorys: {
    name: '工廠登記',
    searchKey: 'factorys'
  },
  verdicts: {
    name: '法律訴訟',
    searchKey: 'verdicts'
  },
  stock: {
    name: '關係人地圖',
    searchKey: 'stock'
  },
  stock_relationship_chart: {
    name: '股權結構分析圖',
    searchKey: 'stock'
  },
  stock_directed_chart: {
    name: '企業關聯圖譜',
    searchKey: 'stock'
  },
  news: {
    name: '新聞事件',
    searchKey: 'news'
  },
  disputes: {
    name: '違反勞動法令案件',
    searchKey: 'disputes'
  },
  procurement_bad_vendors: {
    name: '拒絕往來廠商紀錄',
    searchKey: 'procurement_bad_vendors'
  },
  trades: {
    name: '進出口實續',
    searchKey: 'trades'
  },
  procurements: {
    name: '政府標案',
    searchKey: 'procurements'
  },
  jobs: {
    name: '人力招聘',
    searchKey: 'job104s_jobs'
  },
  patents: {
    name: '專利資訊',
    searchKey: 'patents'
  },
  awards: {
    name: '得獎紀錄與政府推薦名單',
    searchKey: 'awards'
  },
  publics_news: {
    name: '公司近期發布之重大訊息',
    searchKey: 'publics_news'
  },
  revenue_history: {
    name: '營收資訊',
    searchKey: 'revenue_history'
  },
  ifrss_summary: {
    name: '採IFRSs後財報資訊',
    searchKey: 'ifrss_summary'
  }
}

// 公司資料項目名稱（api裡"get"的對應名稱）
export const COMPANY_DETAIL_ITEM_DICT = {
  basic: '商工登記資料',
  shareholders: '董監事資料',
  managers: '經理人資料',
  companys_history: '變更紀錄',
  company_branchs: '分公司資料',
  登記地址近似公司: '登記地址近似公司',
  factorys: '工廠登記',
  verdicts: '法律訴訟',
  news: '新聞事件',
  disputes: '違反勞動法令案件',
  procurement_bad_vendors: '拒絕往來廠商紀錄',
  trades: '進出口實續',
  procurements: '政府標案',
  job104s_jobs: '人力招聘',
  patents: '專利資訊',
  awards: '得獎紀錄與政府推薦名單',
  publics_news: '公司近期發布之重大訊息',
  revenue_history: '營收資訊',
  ifrss_summary: '採IFRSs後財報資訊',
  data_gov_decision: '公平交易委員會行政決定',
  data_gov_environmental_punishment: '環保署裁罰處分',
  epa: '列管汙染源資料裁處資訊'
}

export const COMPANY_DETAIL_SOURCE_DICT = {
  basic: 'https://findbiz.nat.gov.tw/fts/query/QueryBar/queryInit.do',
  shareholders: 'https://findbiz.nat.gov.tw/fts/query/QueryBar/queryInit.do',
  managers: 'https://findbiz.nat.gov.tw/fts/query/QueryBar/queryInit.do',
  companys_history: 'http://data.gcis.nat.gov.tw/od/detail?oid=C1DA4270-227F-4E88-8D25-762690C10840',
  company_branchs: 'https://findbiz.nat.gov.tw/fts/query/QueryBar/queryInit.do',
  登記地址近似公司: 'https://findbiz.nat.gov.tw/fts/query/QueryBar/queryInit.do',
  factorys: 'https://findbiz.nat.gov.tw/fts/query/QueryBar/queryInit.do',
  verdicts: 'https://law.judicial.gov.tw/FJUD/default_accfree.aspx',
  news: '藍星球新聞中心',
  disputes: 'https://announcement.mol.gov.tw/',
  procurement_bad_vendors: 'https://web.pcc.gov.tw/vms/rvlm/rvlmPublicSearch/indexSearchRvlmPublic',
  trades: 'https://data.gov.tw/dataset/79641',
  procurements: 'https://pcc.g0v.ronny.tw/',
  jobs: 'http://www.104.com.tw/',
  patents: 'https://tiponet.tipo.gov.tw/TipoMenu/',
  awards: 'https://data.gov.tw/',
  publics_news: 'https://mops.twse.com.tw/mops/web/t05st02',
  revenue_history: 'https://mops.twse.com.tw/mops/web/t51sb01',
  ifrss_summary: 'https://mops.twse.com.tw/mops/web/t51sb01',
  data_gov_decision: 'https://www.ftc.gov.tw/internet/main/decision/decisionlist.aspx',
  data_gov_environmental_punishment: 'https://www.epa.gov.tw/Page/A333742DB5A89DCD',
  epa: 'https://prtr.epa.gov.tw/Penalty/Query'
}

// 人物資料項目名稱
export const PERSON_DETAIL_ITEM_DICT = {
  companys: '相關公司列表',
  verdicts: '法律訴訟',
  news: '新聞事件'
}

// 後分類查詢項目名稱
export const FILTER_ITEM_DICT = {
  data_type: '公司類型',
  company_state: '公司狀態',
  etax_matters: '行業別',
  company_operate: '營業狀態',
  company_county: '所在地區',
  date_approved: '成立年限',
  capital_amount: '資本總額',
  employee_count: '員工人數',
  stock_state: '股權狀況',
  public_issue: '公開發行',
  imports: '最近一年度總進口實績',
  exports: '最近一年度總出口實績',
  procurement_history: '最近一個月得標金額'
}


// 判決書篩選選單名稱
export const VERDICTS_FILTER_ITEM_DICT = {
  court: '審判法院',
  JType: '案件類型',
  people: '提及人物',
  time: '時間範圍'
}

// 判決書全文提及關鍵字選單名稱
export const VERDICTS_HIGHLIGHT_ITEM_DICT = {
  company: '提及公司',
  representative: '提及代表人',
  shareHolders: '提及董事',
  managers: '提及經理人'
}

// 新聞篩選選單名稱
export const NEWS_FILTER_ITEM_DICT = {
  adept_all: '熱門關鍵字',
  negative: '潛在風險',
  company: '提及公司名稱',
  people: '提及人物',
  time: '時間範圍'
}

// 新聞全文提及關鍵字選單名稱
export const NEWS_HIGHTLIGHT_ITEM_DICT = {
  adept_brand: '提及企業品牌',
  adept_people: '提及人物',
  adept_location: '提及地點',
  adept_keyword: '提及關鍵字'
}

// 系統預設的角色
export const SYSTEM_ROLES = ['最高管理者', '單位管理者', '一般使用者']
