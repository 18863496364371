import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store/index.js'

/**
 * 查詢api service
 */

// axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_CRAWLER_API_URL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (store.state.jwtToken) {
      config.headers['Authorization'] = `Bearer ${store.state.jwtToken}`
    }
    return config
  },
  error => {
    // if (process.env.VUE_APP_MODE === 'serve') {
    //   console.log(error) // for debug
    // }
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    // 有回傳但success不為true
    if (!res.success) {
      let showMessage = ''
      let backendMessage = store.state.errorCodes[res.message] // 比對錯誤代碼表
      if (backendMessage) {
        showMessage = backendMessage
      } else if (res.message) {
        showMessage = res.message // 後端的錯誤訊息（不在代碼表裡）
      } else {
        showMessage = '未知錯誤，請確認網路狀態或聯絡系統管理員'
      }
      Message({
        message: showMessage,
        type: 'error',
        duration: 5 * 1000
      })

      return Promise.reject(res.message || 'Error')
    }
    
    return res
  },
  error => {
    // if (process.env.VUE_APP_MODE === 'serve') {
    //   console.log(error) // for debug
    // }
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
