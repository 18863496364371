export default [
  {
    name: 'login',
    path: '/login',
    meta: {
      type: 'page',
      title: '登入頁'
    },
    component: () => import('@/views/login/Index.vue')
  },
  {
    name: 'index',
    alias: ['/'],
    path: '/home',
    meta: {
      type: 'page',
      title: '首頁'
    },
    component: () => import('@/views/index/Index.vue'),
    children: [
      {
        name: 'home',
        path: '/home',
        meta: {
          type: 'main',
          title: '查詢頁'
        },
        component: () => import('@/views/index/home/Index.vue')
      },
      {
        name: 'companyList',
        path: '/companylist/:dataTypeIndex',
        props: true,
        meta: {
          type: 'main',
          title: '搜尋結果',
          queryEncode: true
        },
        component: () => import('@/views/index/companyList/Index.vue')
      },
      {
        name: 'companyDetail',
        path: '/companydetail',
        meta: {
          type: 'main',
          title: '公司詳細資料'
        },
        component: () => import('@/views/index/companyDetail/Index.vue')
      },
      {
        name: 'multiCompanyDetail',
        path: '/multicompanydetail/:menuIndex',
        props: true,
        meta: {
          type: 'main',
          title: '比較公司'
        },
        component: () => import('@/views/index/multiCompanyDetail/Index.vue'),
        children: [
          {
            name: 'multiCompanyDetailByCompany',
            path: '/multicompanydetail/bycompany/:menuIndex',
            props: true,
            meta: {
              type: 'container',
              title: '比較公司（依比較公司呈現）',
              queryEncode: true
            },
            component: () => import('@/views/index/multiCompanyDetail/byCompany/Index.vue')
          },
          {
            name: 'multiCompanyDetailByItem',
            path: '/multicompanydetail/byitem/:menuIndex',
            props: true,
            meta: {
              type: 'container',
              title: '比較公司（依比較項目呈現）',
              queryEncode: true
            },
            component: () => import('@/views/index/multiCompanyDetail/byItem/Index.vue')
          }
        ]
      },
      {
        name: 'multiPersonDetail',
        path: '/multipersondetail/byperson/:menuIndex',
        meta: {
          type: 'main',
          title: '比較人物'
        },
        component: () => import('@/views/index/multiPersonDetail/Index.vue'),
        children: [
          {
            name: 'multiPersonDetailByPerson',
            path: '/multipersondetail/byperson/:menuIndex',
            props: true,
            meta: {
              type: 'container',
              title: '比較人物（依比較人物呈現）',
              queryEncode: true
            },
            component: () => import('@/views/index/multiPersonDetail/byPerson/Index.vue')
          },
          {
            name: 'multiPersonDetailByItem',
            path: '/multipersondetail/byitem/:menuIndex',
            props: true,
            meta: {
              type: 'container',
              title: '比較人物（依比較項目呈現）',
              queryEncode: true
            },
            component: () => import('@/views/index/multiPersonDetail/byItem/Index.vue')
          }
        ]
      },
      {
        name: 'newsDetail',
        path: '/newsDetail/:uniID',
        props: true,
        meta: {
          type: 'main',
          title: '新聞',
          queryEncode: true
        },
        component: () => import('@/views/index/newsDetail/Index.vue')
      },
      {
        name: 'verdictDetail',
        path: '/verdictDetail/:uniID',
        props: true,
        meta: {
          type: 'main',
          title: '判決全文',
          queryEncode: true
        },
        component: () => import('@/views/index/verdictDetail/Index.vue')
      },
      // {
      //   name: 'favorites',
      //   path: '/favorites',
      //   component: () => import('@/views/index/Favorites.vue')
      // },
      {
        name: 'manage',
        alias: ['/manage'],
        path: '/manage/admin',
        meta: {
          type: 'main',
          title: '管理'
        },
        component: () => import('@/views/index/manage/Index.vue'),
        children: [
          {
            name: 'manageAdmin',
            path: '/manage/admin',
            meta: {
              type: 'container',
              title: '管理者功能'
            },
            component: () => import('@/views/index/manage/admin/Index.vue')
          },
          {
            name: 'managePersonal',
            path: '/manage/personal',
            meta: {
              type: 'container',
              title: '個人資料'
            },
            component: () => import('@/views/index/manage/personal/Index.vue')
          },
          {
            name: 'manageReport',
            path: '/manage/report',
            meta: {
              type: 'container',
              title: '問題回報'
            },
            component: () => import('@/views/index/manage/report/Index.vue')
          },
          {
            name: 'manageCustumKeywords',
            path: '/manage/customKeywords',
            meta: {
              type: 'container',
              title: '潛在風險關鍵字'
            },
            component: () => import('@/views/index/manage/customKeywords/Index.vue')
          }
        ]
      },
      {
        name: 'collections',
        path: '/collections',
        meta: {
          type: 'main',
          title: '我的收藏'
        },
        component: () => import('@/views/index/collections/Index.vue')
      },
      // {
      //   name: 'transition',
      //   path: '/transition',
      //   meta: {
      //     type: 'main',
      //     title: '過渡頁'
      //   },
      //   component: () => import('@/views/index/transition/Transition.vue')
      // },
    ]
  },
  {
    name: 'map',
    path: '/map',
    meta: {
      type: 'page',
      title: '地圖'
    },
    component: () => import('@/views/map/Index.vue')
  },
  // 測試用
  {
    name: 'companyStockRelationship',
    path: '/chart/company-stock-relationship',
    meta: {
      type: 'page',
      title: '股權結構分析圖'
    },
    component: () => import('@/components/CompanyStockRelationshipChart_legacy/CompanyStockRelationshipChart.vue')
  },
  {
    name: 'companyStockDirected',
    path: '/chart/company-stock-directed',
    meta: {
      type: 'page',
      title: '企業關聯圖譜'
    },
    component: () => import('@/components/CompanyStockDirectedChart_legacy/CompanyStockDirectedChart.vue')
  }
]
