export default [
  {
    label: '0年',
    value: 0
  },
  {
    label: '1年',
    value: 1
  },
  {
    label: '5年',
    value: 5
  },
  {
    label: '10年',
    value: 10
  },
  {
    label: '20年',
    value: 20
  },
  {
    label: '30年',
    value: 30
  },
  {
    label: '50年',
    value: 50
  },
  {
    label: '100年',
    value: 100
  },
  {
    label: '100年以上',
    value: 9999999999999
  }
]
