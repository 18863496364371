import store from '@/store/index'
import * as apis from '@/apis/index.ts'
import { MessageBox } from 'element-ui'

function getLoginStatus () {
  const userInfo = store.state.userInfo
  let isLogin = false
  let jwtTokenPayload = store.state.jwtTokenPayload
  if (userInfo &&
  jwtTokenPayload &&
  jwtTokenPayload.exp) {
    try {
      let expireTimeStamp = jwtTokenPayload.exp * 1000
      let currentTimeStamp = new Date().getTime()
      if (expireTimeStamp > currentTimeStamp) {
        isLogin = true // 登入在有效期限內
      }
      // console.log('isLogin', isLogin)
      // console.log('expireTimeStamp', expireTimeStamp, new Date(expireTimeStamp))
      // console.log('currentTimeStamp', currentTimeStamp, new Date(currentTimeStamp))
      // console.log('expireTimeStamp - currentTimeStamp', expireTimeStamp - currentTimeStamp)
    } catch (e) { throw new Error(e) }
  }
  return isLogin
}

// 定期檢查登入狀態（星展特規）
let intervalID = null
function watchLoginTimmer (originIsLogin) {
  // 先清除
  if (intervalID != null) {
    clearInterval(intervalID)
  }
  if (originIsLogin) {
    // 有登入的話開始啟用定期檢查
    intervalID = setInterval(() => {
      const isLogin = getLoginStatus()
      if (isLogin === false) {
        MessageBox.alert('由於您於頁面閒置超過時間，為維護資訊安全已將您登出系統，敬請重新登入。', '', {
          confirmButtonText: '確定',
          callback: action => {
            store.dispatch('actionLogout')
            window.location = '/'
          }
        })
        clearInterval(intervalID)
      }
    }, 60000)
  }
}

export default async function (to, from, next) {
  let originQuery = null  
  // 取得原始查詢參數
  if (to.query && to.query.query) {
    try {
      // let findRoute = store.state.routeHistory.find(d => {
      //   if (d.route.query &&
      //   d.route.query.query &&
      //   d.route.query.query === to.query.query) {
      //     return true
      //   } else {
      //     return false
      //   }
      // })
      // case 歷史紀錄有資料直接取得
      // if (findRoute && findRoute.route.query) {
      //   originQuery = findRoute.route.query
      // } else {
        // case 歷史紀錄無資料，跟後端查
        let result = await apis.decode({ key: to.query.query })
        if (result.success === true) {
          originQuery = JSON.parse(result.value)
        }
      // }
    } catch (e) {
      next({ name: 'home' })
      throw new Error(e)
    }
  } else {
    originQuery = to.query
  }
  // 更新查詢參數
  store.commit('mutationOriginRouteQuery', originQuery)
  // -- 紀錄路由歷史紀錄 --
  const routeHistory = {
    route: to,
    originQuery,
    datetime: new Date()
  }
  store.commit('mutationRouteHistoryAdd', routeHistory)
  window.bplog.routeHistory.push(routeHistory)
  // @Q@ 測試跳過權限
  // next()
  // return
  // -- 登入權限處理 --
  // let promiseIsLogin = await store.dispatch('actionCheckLoginStatus')
  const userInfo = store.state.userInfo
  let isLogin = getLoginStatus()
  
  // 定期檢查登入狀態（星展特規）
  watchLoginTimmer(isLogin)

  if (isLogin === true) {
    // -- 已登入 --
    if (to.name === 'login') {
      next({ name: 'home' })
    } else {
      next()
    }
  } else {
    // -- 未登入 --
    store.commit('mutationIsLogin', false)
    if (to.name === 'login') {
      next()
    } else {
      next({ name: 'login' })
    }
  }
}
