export default [
  {
    index: 0,
    label: '0',
    value: 0
  },
  {
    index: 1,
    label: '100萬',
    value: 1000000
  },
  {
    index: 2,
    label: '500萬',
    value: 5000000
  },
  {
    index: 3,
    label: '1000萬',
    value: 10000000
  },
  {
    index: 4,
    label: '1000萬以上',
    value: 9999999999999
  }
]
