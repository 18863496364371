export default {
  // originSearchQuery: null,
  currentSearchQuery: null, // 目前的查詢條件
  // currentFilterQueryString: null,
  isPageLoading: false,
  isCompanyListLoading: false,
  sidebarMenu: [],
  companyList: [],
  companyCount: 0,
  companySearchableCount: 0,
  isCollections: {}, // 加入收藏
  // companyListQuery: {}
  dataTypeOptions: [],
  // companyOperateOptions: [],
  companyStateOptions: [],
  companyOperateOptions: [],
  companyCountyOptions: [],
  dateApprovedOptions: [],
  dateApprovedSliderOptions: [],
  capitalAmountOptions: [],
  capitalAmountSliderOptions: [],
  stockStateOptions: [],
  publicIssueOptions: [],
  importsOptions: [],
  importsSliderOptions: [],
  exportsOptions: [],
  exportsSliderOptions: [],
  procurementHistoryOptions: [],
  procurementHistorySliderOptions: []
}
