export default {
  // basicData: null, // 「基本資料」頁籤資料
  // riskData: null, // 「風險指標」頁籤資料
  // operatingData: null, // 「經營指標」頁籤資料
  // financeData: null, // 「財務指標」頁籤資料
  companyData: {}, // 全部的資料
  companyNews: null, // 新聞資料
  downloadCompanyData: null,
  isGoFullLoading: false,
  companyGoFull: {
    nodes: [],
    edges: []
  },
  companyGoOld: {
    nodes: [],
    edges: []
  },
  goUniID: '',
}
