export default {
  uniIDList: [],
  getList: [], // 查詢'get'的參數key轉換為陣列
  showList: [],
  // companyList: [], // 查詢公司清單
  multiCompanyData: [], // 全部公司的全部的資料
  multiCompanyNews: [], // 全部公司的news資料
  multiGoFull: {}, // { uniID: { nodes: [], edges: [] } }
  multiGoOld: {}, // { uniID: { nodes: [], edges: [] } }
  isCompanyDataLoading: true,
  isCompanyNewsLoading: true,
  // isGoFullLoading: true,
  // isGoOldLoading: true,
  isShowDescription: false,
  isShowBasic: false,
  isShowShareholders: false,
  isShowManagers: false,
  isShowCompanysHistory: false,
  isShowCompanyBranchs: false,
  isShowFactorys: false,
  isShowSimilarAddress: false,
  isShowVerdicts: false,
  isShowNews: false,
  isShowStockRelationshipChart: false,
  isShowStockDiractedChart: false,
  isShowStock: false,
  isShowDisputes: false,
  isShowProcurementBadVendors: false,
  isShowTrades: false,
  isShowProcurements: false,
  isShowJobs: false,
  isShowPatents: false,
  isShowAwards: false,
  isShowPublicsNews: false,
  isShowRevenueHistory: false,
  isShowIfrssSummary: false
}
