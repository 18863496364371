import * as apis from '@/apis/index.ts'

export default {
  // 公司資料（取得除了news以外的所有資料）
  async actionMultiCompanyDetail ({ dispatch, commit, state, rootGetters }, { uniIDList, get }) {
    // 初次查詢先清空
    commit('mutationResetMultiGo')
    
    // 所有資料查詢
    let promiseList = []
    uniIDList.forEach(d => {
      const searchRequest = {
        get,
        uniID: d
      }
      promiseList.push(apis.companyGet(searchRequest))
    })

    commit('mutationIsCompanyDataLoading', true)
    let result = await Promise.all(promiseList)
      .then(dataArr => {
        commit('mutationIsCompanyDataLoading', false)
        // 所有公司資料
        const multiCompanyData = dataArr.map(d => d.payload)
        commit('mutationMultiCompanyData', multiCompanyData)
        // 公司清單
        // const companyList = dataArr.map(d => {
        //   return {
        //     companyName: d.payload.basic.company_name,
        //     uniID: d.payload.basic.uniID
        //   }
        // })
        // commit('mutationCompanyList', multiCompanyData)
        return true
      })
      .catch(e => {
        return false
      })
    return result
  },
  // 單獨取新聞資料
  async actionMultiCompanyNews ({ dispatch, commit, state, rootState }, uniIDList) {
    let promiseList = []
    uniIDList.forEach(d => {
      const searchRequest = {
        get: {
          news: {
            // 'remove-duplicate': 1
            // 陣列轉物件
            'cls-custom': rootState.riskKeywordGroup.reduce((prev, current) => {
              if (current.switch == 0) {
                return prev
              }
              return {
                ...prev,
                [current.keyword_group_name]: current.keywords
              }
            }, {})
          }
        },
        uniID: d
      }
      promiseList.push(apis.companyGet(searchRequest))
    })

    commit('mutationIsCompanyNewsLoading', true)
    Promise.all(promiseList)
      .then(dataArr => {
        commit('mutationIsCompanyNewsLoading', false)
        // 所有公司資料
        const multiCompanyNews = dataArr.map(d => d.payload.news)
        commit('mutationMultiCompanyNews', multiCompanyNews)
      })
      .catch(e => {
      })
  },
  // 商業網絡圖資料
  // async actionMultiCompanyGraphForDbs ({ dispatch, commit, state, rootState }, uniIDList) {
  //   // 所有資料查詢
  //   let promiseFullList = []
  //   let promiseOldList = []
  //   uniIDList.forEach(d => {
  //     const fullRearchRequest = {
  //       uniid: d,
  //       skip_gov_neibor: '1',
  //       skip_independent_director_neibor: '1',
  //       only_in_bussiness: false
  //     }
  //     promiseFullList.push(apis.graphForDbs(fullRearchRequest))

  //     const oldRearchRequest = {
  //       uniid: d,
  //       "mode": "up",
  //       "format": "json",
  //       "mini": "0",
  //       "scope": "classic",
  //       "force_split": "1",
  //       only_in_bussiness: false
  //     }
  //     promiseUpList.push(apis.graphForDbsOld(oldRearchRequest))
  //   })

  //   commit('mutationCompanyGoFull', [])
  //   commit('mutationCompanyGoOld', [])
  //   commit('mutationIsGoFullLoading', true)
  //   commit('mutationIsGoOldLoading', true)
  //   let result1 = await Promise.all(promiseFullList)
  //     .then(dataArr => {
  //       commit('mutationIsGoFullLoading', false)
  //       const multiCompanyData = dataArr.map(d => d.payload)
  //       commit('mutationMultiGoFull', multiCompanyData)
  //       return true
  //     })
  //     .catch(e => {
  //       return false
  //     })
  //   let result2 = await Promise.all(promiseOldList)
  //     .then(dataArr => {
  //       commit('mutationIsGoOldLoading', false)
  //       const multiCompanyData = dataArr.map(d => d.payload)
  //       commit('mutationMultiGoOld', multiCompanyData)
  //       return true
  //     })
  //     .catch(e => {
  //       return false
  //     })
  //   return result1 && result2
  // }
}