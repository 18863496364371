import * as apis from '@/apis/index.ts'

export default {
  // 開啟網站取得初始化資料
  actionInitData ({ dispatch, commit, state }, payload) {
    commit('mutationIsFullPageLoading', true)
    // -- 帳號及權限相關資料 --
    const promiseRoles = apis.roles()
      .then(result => {
        commit('mutationRoles', result.payload)
        return result
      })
      .catch(e => e)
    const promiseUsers = apis.users()
      .then(result => {
        commit('mutationUsers', result.payload)
        return result
      })
      .catch(e => e)
    const promiseUnits = apis.units()
      .then(result => {
        commit('mutationUnits', result.payload)
        return result
      })
      .catch(e => e)
    const promiseLimits = apis.limits()
      .then(result => {
        commit('mutationLimits', result.payload)
        return result
      })
      .catch(e => e)
    const promiseKeywordGroup = apis.riskKeywordGroup()
      .then(result => {
        commit('mutationRiskKeywordGroup', result.payload)
        return result
      })
      .catch(e => e)
    const promiseCodes = apis.errorCodes()
      .then(result => {
        commit('mutationErrorCodes', result.payload)
        return result
      })
      .catch(e => e)
    Promise.all([promiseRoles, promiseUsers, promiseUnits, promiseLimits, promiseKeywordGroup, promiseCodes])
      .then(_ => {
        commit('mutationIsFullPageLoading', false)
      })
      .catch(_ => {
        commit('mutationIsFullPageLoading', false)
      })    
  },
  // 確認是否登入
  /*
  async actionCheckLoginStatus ({ dispatch, commit, state }, payload) {
    const token = localStorage.getItem('bp__dbs__token') || state.jwtToken
    if (!token) {
      // 未登入
      localStorage.removeItem('bp__dbs__token')
      commit('mutationUserInfo', null)
      commit('mutationIsLogin', false)
      return {
        success: false,
        message: '',
        payload: null
      }
    }
    const result = await apis.userSession()
    if (result.success === true && result.payload) {
      // 已登入
      commit('mutationUserInfo', result.payload)
      commit('mutationIsLogin', true)
    } else {
      // -- 登入驗證失敗 --
      localStorage.removeItem('bp__dbs__token')
      commit('mutationUserInfo', null)
      commit('mutationIsLogin', false)
      return {
        success: false,
        message: '',
        payload: null
      }
    }
    return result
  },
  */
  // 登入
  async actionLogin ({ dispatch, commit, state }, { email, password }) {
    const result = await apis.userLogin({
      email,
      password
    })
    console.log(result)
    if (result.success === true && result.payload && result.payload.jwt_token && result.payload.user_info) {
      commit('mutationJwtToken', result.payload.jwt_token)
      commit('mutationIsLogin', true)
      commit('mutationUserInfo', result.payload.user_info)
    } else {
      // -- 登入失敗 --
      commit('mutationJwtToken', null)
      commit('mutationUserInfo', null)
      commit('mutationIsLogin', false)
    }
    return result
  },
  // 登出
  async actionLogout ({ dispatch, commit, state }, payload) {
    commit('mutationJwtToken', null)
    commit('mutationUserInfo', null)
    commit('mutationIsLogin', false)

    const result = await apis.userLogout()
    return result
  },
  // 重新取得userInfo
  // @Q@ 目前這支api資料沒很完整
  // async actionUserInfo ({ dispatch, commit, state }) {
  //   const request = {
  //     email: state.userInfo.email
  //   }
  //   const result = await apis.user(request)
  //   commit('mutationUserInfo', result.payload)
  //   return result
  // },
  async actionRiskKeywordGroup ({ dispatch, commit, state }) {
    return apis.riskKeywordGroup()
      .then(result => {
        commit('mutationRiskKeywordGroup', result.payload)
      })
      .catch(e => e)
  },
}
