/*
 * 格式化空值
 */

import Vue from 'vue'

Vue.filter('emptyFormat', function (v) {
  if (v == null || v == '') {
    return '-'
  }
  // 負數視為空值
  if (isNaN(v) === false && v < 0) {
    return '-'
  }
  return v
})