import * as apis from '@/apis/index.ts'

export default {
  // 取得查詢選單
  async actionOptions ({ dispatch, commit, state }, payload) {
    const searchRequest = {
      basic: {
        get: ['cls'],
        ignore_keyword: 1
      }
    }
    let result = await apis.companyListGet(searchRequest)
      .then((data) => {
        commit('mutationOptions', data.payload.cls)
        return true
      })
      .catch(e => {
        return false
      })
    return result
  },
}
