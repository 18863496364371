export default [
  {
    label: '0',
    value: 0
  },
  {
    label: '10萬',
    value: 100000
  },
  {
    label: '100萬',
    value: 1000000
  },
  {
    label: '1000萬',
    value: 10000000
  },
  {
    label: '1億',
    value: 100000000
  },
  {
    label: '10億',
    value: 1000000000
  },
  {
    label: '100億',
    value: 10000000000
  },
  {
    label: '100億以上',
    value: 9999999999999
  }
]
